import React, { useEffect } from "react";
import { useTools } from "@/contexts/ToolsContext";
import { getCategoryName } from "@/contexts/toolsData";
import { ToolGrid } from "@/components/ToolGrid";
import {
  ArrowLeftRight,
  MoveHorizontal,
  Scale,
  Clock,
  Ruler,
  Thermometer,
  Globe,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const ConvertersPage: React.FC = () => {
  const { setFilterCategory } = useTools();
  const category = "converters";

  // Set the filter category when the component mounts
  useEffect(() => {
    setFilterCategory(category);

    // Set the document title
    document.title = getCategoryTitle();
  }, [setFilterCategory]);

  // Generate SEO title based on category
  const getCategoryTitle = () => {
    const categoryName = getCategoryName(category);
    return `${categoryName} - Free Online ${categoryName} Tools | ToolNames`;
  };

  return (
    <div className="space-y-12 pb-8">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-violet-50 to-indigo-50 dark:from-violet-950/30 dark:to-indigo-950/30 rounded-xl overflow-hidden">
        <div className="container py-12 px-4 md:px-6">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h1 className="text-3xl md:text-4xl font-bold mb-4">
                Unit Conversion Made Simple
              </h1>
              <p className="text-lg text-muted-foreground mb-6">
                Free conversion tools for all types of units and measurements.
                Convert between different systems instantly with our easy-to-use
                tools that run directly in your browser.
              </p>
              <div className="flex flex-wrap gap-4">
                <div className="flex items-center text-sm">
                  <Ruler className="h-4 w-4 mr-2 text-violet-500" />
                  <span>Length Converters</span>
                </div>
                <div className="flex items-center text-sm">
                  <Scale className="h-4 w-4 mr-2 text-indigo-500" />
                  <span>Weight Converters</span>
                </div>
                <div className="flex items-center text-sm">
                  <Thermometer className="h-4 w-4 mr-2 text-blue-500" />
                  <span>Temperature Converters</span>
                </div>
                <div className="flex items-center text-sm">
                  <Clock className="h-4 w-4 mr-2 text-purple-500" />
                  <span>Time Converters</span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <Link to="/converters/unit-converter" className="block">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border border-violet-200 dark:border-violet-900 h-full">
                  <div className="bg-violet-100 dark:bg-violet-900/30 rounded-full p-2 mb-4">
                    <ArrowLeftRight className="h-8 w-8 text-violet-500" />
                  </div>
                  <div className="px-1 py-0.5 text-xs rounded-full bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100 mb-2">
                    POPULAR
                  </div>
                  <h3 className="font-medium mb-2">Unit Converter</h3>
                  <p className="text-sm text-muted-foreground">
                    Convert between multiple unit types with a single tool
                  </p>
                </div>
              </Link>
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border">
                <Globe className="h-12 w-12 text-indigo-500 mb-4" />
                <h3 className="font-medium mb-2">Currency Converter</h3>
                <p className="text-sm text-muted-foreground">
                  Convert between currencies with up-to-date exchange rates
                </p>
              </div>
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border">
                <Clock className="h-12 w-12 text-blue-500 mb-4" />
                <h3 className="font-medium mb-2">Time Zone Converter</h3>
                <p className="text-sm text-muted-foreground">
                  Convert times between different time zones around the world
                </p>
              </div>
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border">
                <Scale className="h-12 w-12 text-purple-500 mb-4" />
                <h3 className="font-medium mb-2">Measurement Converters</h3>
                <p className="text-sm text-muted-foreground">
                  Specialized converters for various measurement types
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Tool Section */}
      <section className="container">
        <div className="bg-gradient-to-r from-indigo-50 to-violet-50 dark:from-indigo-950/30 dark:to-violet-950/30 rounded-xl overflow-hidden py-8 px-6">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <div className="flex items-center mb-2">
                <ArrowLeftRight className="h-5 w-5 mr-2 text-violet-500" />
                <span className="text-sm font-medium text-violet-500">
                  FEATURED CONVERTER
                </span>
              </div>
              <h2 className="text-2xl font-bold mb-4">
                Universal Unit Converter
              </h2>
              <p className="text-muted-foreground mb-6">
                A powerful unit conversion tool that handles over 100 different
                unit types across multiple categories. Whether you need to
                convert length, weight, volume, temperature, or more, our
                converter has you covered.
              </p>
              <div className="space-y-3">
                <div className="flex items-start">
                  <MoveHorizontal className="h-5 w-5 mr-2 text-violet-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">Multiple Categories</h4>
                    <p className="text-sm text-muted-foreground">
                      Convert units across 12+ categories including length,
                      weight, volume, and more
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <Globe className="h-5 w-5 mr-2 text-violet-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">International Standards</h4>
                    <p className="text-sm text-muted-foreground">
                      Supports both metric and imperial units with high
                      precision conversions
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <ArrowLeftRight className="h-5 w-5 mr-2 text-violet-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">Bidirectional Conversion</h4>
                    <p className="text-sm text-muted-foreground">
                      Convert in either direction with real-time updates as you
                      type
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <Link to="/converters/unit-converter">
                  <Button>Try Unit Converter</Button>
                </Link>
              </div>
            </div>
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden border">
              <div className="p-6">
                <div className="bg-gradient-to-br from-violet-50 to-indigo-50 dark:from-violet-900/20 dark:to-indigo-900/20 rounded-lg p-6">
                  <div className="flex justify-between items-center mb-6">
                    <h3 className="font-medium">Length Converter</h3>
                    <div className="bg-white dark:bg-gray-800 px-3 py-1 rounded-full text-xs shadow-sm">
                      Metric ⟷ Imperial
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <div className="text-sm text-muted-foreground mb-1">
                        From
                      </div>
                      <div className="flex mb-2">
                        <div className="h-10 flex-1 bg-white dark:bg-gray-700 rounded-l-md border border-gray-200 dark:border-gray-600"></div>
                        <div className="h-10 w-20 bg-violet-100 dark:bg-violet-900/30 border-y border-r border-gray-200 dark:border-gray-600 rounded-r-md flex items-center justify-center text-sm">
                          meters
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-muted-foreground mb-1">
                        To
                      </div>
                      <div className="flex mb-2">
                        <div className="h-10 flex-1 bg-white dark:bg-gray-700 rounded-l-md border border-gray-200 dark:border-gray-600"></div>
                        <div className="h-10 w-20 bg-indigo-100 dark:bg-indigo-900/30 border-y border-r border-gray-200 dark:border-gray-600 rounded-r-md flex items-center justify-center text-sm">
                          feet
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center justify-center text-center">
                      <div>
                        <div className="text-xl font-medium">1</div>
                        <div className="text-xs text-muted-foreground">
                          meter
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-center text-center">
                      <div>
                        <div className="text-xl font-medium">3.28084</div>
                        <div className="text-xs text-muted-foreground">
                          feet
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center mt-4">
                    <ArrowLeftRight className="h-6 w-6 text-violet-500" />
                  </div>

                  <div className="mt-4 grid grid-cols-3 gap-2">
                    <div className="text-xs bg-white dark:bg-gray-800 p-2 border border-gray-200 dark:border-gray-700 rounded text-center">
                      <div className="font-medium">100 cm</div>
                      <div className="text-muted-foreground">1 m</div>
                    </div>
                    <div className="text-xs bg-white dark:bg-gray-800 p-2 border border-gray-200 dark:border-gray-700 rounded text-center">
                      <div className="font-medium">1 km</div>
                      <div className="text-muted-foreground">1000 m</div>
                    </div>
                    <div className="text-xs bg-white dark:bg-gray-800 p-2 border border-gray-200 dark:border-gray-700 rounded text-center">
                      <div className="font-medium">1 mi</div>
                      <div className="text-muted-foreground">1609.34 m</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Tools Grid Section */}
      <section>
        <div className="container">
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Converter Tools</h2>
            <p className="text-muted-foreground">
              All converters run entirely in your browser with no server
              dependencies. Your data never leaves your device, ensuring
              complete privacy.
            </p>
          </div>
          <ToolGrid />
        </div>
      </section>

      {/* Conversion Reference Section */}
      <section className="bg-gray-50 dark:bg-gray-900/50 py-8 rounded-lg">
        <div className="container px-4 md:px-6">
          <h2 className="text-2xl font-bold mb-6">
            Quick Conversion Reference
          </h2>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <Ruler className="h-5 w-5 mr-2 text-violet-500" />
                Length Conversions
              </h3>
              <p className="text-sm text-muted-foreground">
                1 inch = 2.54 cm
                <br />
                1 foot = 30.48 cm
                <br />
                1 meter = 3.28 feet
                <br />1 mile = 1.61 kilometers
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <Scale className="h-5 w-5 mr-2 text-indigo-500" />
                Weight Conversions
              </h3>
              <p className="text-sm text-muted-foreground">
                1 kg = 2.20462 pounds
                <br />
                1 pound = 453.592 grams
                <br />
                1 stone = 14 pounds
                <br />1 ounce = 28.3495 grams
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <Thermometer className="h-5 w-5 mr-2 text-blue-500" />
                Temperature Conversions
              </h3>
              <p className="text-sm text-muted-foreground">
                °F to °C: (°F - 32) × 5/9
                <br />
                °C to °F: (°C × 9/5) + 32
                <br />
                Freezing point of water: 0°C / 32°F
                <br />
                Boiling point of water: 100°C / 212°F
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ConvertersPage;
