import React from "react";
import { motion } from "framer-motion";
import { NumberFrequency } from "./LotteryTypes";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LineChart,
  Line,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";
import { formatLargeNumber } from "./LotteryUtils";

interface LotteryBallProps {
  number: number;
  size?: "sm" | "md" | "lg";
  type?: "main" | "bonus";
  isHot?: boolean;
  isCold?: boolean;
  isOverdue?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  animationDelay?: number;
}

/**
 * Animated lottery ball component
 */
export const LotteryBall: React.FC<LotteryBallProps> = ({
  number,
  size = "md",
  type = "main",
  isHot = false,
  isCold = false,
  isOverdue = false,
  isSelected = false,
  onClick,
  animationDelay = 0,
}) => {
  // Determine size class
  const sizeClass = {
    sm: "w-8 h-8 text-sm",
    md: "w-12 h-12 text-base",
    lg: "w-16 h-16 text-xl",
  }[size];

  // Determine color class based on type and status
  let colorClass = "";

  if (isSelected) {
    colorClass = "bg-primary text-primary-foreground";
  } else if (type === "main") {
    if (isHot) {
      colorClass = "bg-red-100 text-red-800 border-red-300";
    } else if (isCold) {
      colorClass = "bg-blue-100 text-blue-800 border-blue-300";
    } else if (isOverdue) {
      colorClass = "bg-amber-100 text-amber-800 border-amber-300";
    } else {
      colorClass = "bg-muted text-muted-foreground border-border";
    }
  } else {
    // Bonus ball
    colorClass = "bg-amber-100 text-amber-800 border-amber-300";
  }

  return (
    <motion.div
      initial={{ scale: 0, rotate: -180 }}
      animate={{ scale: 1, rotate: 0 }}
      exit={{ scale: 0, rotate: 180 }}
      transition={{
        duration: 0.5,
        delay: animationDelay,
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
      className={`${sizeClass} ${colorClass} rounded-full flex items-center justify-center font-bold border shadow-sm ${
        onClick ? "cursor-pointer hover:opacity-80" : ""
      }`}
      onClick={onClick}
    >
      {number}
    </motion.div>
  );
};

interface OddsVisualizationProps {
  odds: number;
  height?: number;
}

/**
 * Odds visualization component
 */
export const OddsVisualization: React.FC<OddsVisualizationProps> = ({
  odds,
  height = 300,
}) => {
  const chartData = [
    { name: "Win", value: 1, color: "#10b981" },
    { name: "Lose", value: odds - 1, color: "#ef4444" },
  ];

  return (
    <div style={{ height: `${height}px` }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={chartData}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            innerRadius={40}
            fill="#8884d8"
            dataKey="value"
            label={(entry) => {
              // Only show "Win" label, "Lose" would be too small
              if (entry.name === "Win") return entry.name;
              return "";
            }}
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip
            formatter={(value: number, name: string) => {
              if (name === "Win") return ["1 ticket", "Win"];
              return [formatLargeNumber(value), "Lose"];
            }}
          />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
      <p className="text-xs text-center mt-2 text-muted-foreground">
        Note: This visualization is not to scale due to the extreme odds. The
        win segment is greatly exaggerated for visibility.
      </p>
    </div>
  );
};

interface FrequencyChartProps {
  data: NumberFrequency[];
  height?: number;
  showLabels?: boolean;
}

/**
 * Number frequency chart component
 */
export const FrequencyChart: React.FC<FrequencyChartProps> = ({
  data,
  height = 300,
  showLabels = true,
}) => {
  return (
    <div style={{ height: `${height}px` }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="number"
            interval={Math.ceil(data.length / 20)}
            fontSize={12}
          />
          <YAxis fontSize={12} />
          <Tooltip
            formatter={(value: number) => [`${value} times`, "Frequency"]}
            labelFormatter={(label) => `Number ${label}`}
          />
          {showLabels && <Legend />}
          <Bar
            dataKey="frequency"
            name="Frequency"
            fill="#8884d8"
            radius={[4, 4, 0, 0]}
          >
            {data.map((entry, index) => {
              let color = "#8884d8"; // Default
              if (entry.isHot) color = "#ef4444"; // Hot (red)
              if (entry.isCold) color = "#3b82f6"; // Cold (blue)
              if (entry.isOverdue) color = "#f59e0b"; // Overdue (amber)

              return <Cell key={`cell-${index}`} fill={color} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

interface PatternChartProps {
  data: Record<string, number>;
  height?: number;
}

/**
 * Pattern analysis radar chart
 */
export const PatternChart: React.FC<PatternChartProps> = ({
  data,
  height = 300,
}) => {
  const chartData = Object.entries(data).map(([key, value]) => ({
    pattern: key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase()),
    value,
    fullMark: 100,
  }));

  return (
    <div style={{ height: `${height}px` }}>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={chartData}>
          <PolarGrid />
          <PolarAngleAxis dataKey="pattern" />
          <PolarRadiusAxis angle={30} domain={[0, 100]} />
          <Radar
            name="Occurrence %"
            dataKey="value"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.6}
          />
          <Tooltip />
          <Legend />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};

interface NumberGridProps {
  min: number;
  max: number;
  selectedNumbers?: number[];
  hotNumbers?: number[];
  coldNumbers?: number[];
  overdueNumbers?: number[];
  onNumberClick?: (num: number) => void;
  size?: "sm" | "md" | "lg";
}

/**
 * Interactive number grid component
 */
export const NumberGrid: React.FC<NumberGridProps> = ({
  min,
  max,
  selectedNumbers = [],
  hotNumbers = [],
  coldNumbers = [],
  overdueNumbers = [],
  onNumberClick,
  size = "md",
}) => {
  const numbers = Array.from({ length: max - min + 1 }, (_, i) => min + i);

  // Calculate grid columns based on range
  const columns = max <= 50 ? 10 : max <= 90 ? 15 : 20;

  return (
    <div
      className={`grid gap-2`}
      style={{ gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))` }}
    >
      {numbers.map((num) => (
        <LotteryBall
          key={num}
          number={num}
          size={size}
          isHot={hotNumbers.includes(num)}
          isCold={coldNumbers.includes(num)}
          isOverdue={overdueNumbers.includes(num)}
          isSelected={selectedNumbers.includes(num)}
          onClick={() => onNumberClick && onNumberClick(num)}
        />
      ))}
    </div>
  );
};

interface DrawHistoryChartProps {
  data: Array<{ date: Date; sum: number }>;
  height?: number;
}

/**
 * Draw history chart component
 */
export const DrawHistoryChart: React.FC<DrawHistoryChartProps> = ({
  data,
  height = 300,
}) => {
  const chartData = data.map((item) => ({
    date: item.date.toLocaleDateString(),
    sum: item.sum,
  }));

  return (
    <div style={{ height: `${height}px` }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={chartData}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            interval={Math.ceil(chartData.length / 10)}
            fontSize={12}
          />
          <YAxis fontSize={12} />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="sum"
            name="Sum of Numbers"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
