import React, { useEffect } from "react";
import { useTools } from "@/contexts/ToolsContext";
import { getCategoryName } from "@/contexts/toolsData";
import { ToolGrid } from "@/components/ToolGrid";
import {
  FileText,
  Key,
  QrCode,
  Wand2,
  Hash,
  Shuffle,
  Lock,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const GeneratorsPage: React.FC = () => {
  const { setFilterCategory } = useTools();
  const category = "generators";

  // Set the filter category when the component mounts
  useEffect(() => {
    setFilterCategory(category);

    // Set the document title
    document.title = getCategoryTitle();
  }, [setFilterCategory]);

  // Generate SEO title based on category
  const getCategoryTitle = () => {
    const categoryName = getCategoryName(category);
    return `${categoryName} - Free Online ${categoryName} Tools | ToolNames`;
  };

  return (
    <div className="space-y-12 pb-8">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-blue-50 to-cyan-50 dark:from-blue-950/30 dark:to-cyan-950/30 rounded-xl overflow-hidden">
        <div className="container py-12 px-4 md:px-6">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h1 className="text-3xl md:text-4xl font-bold mb-4">
                Generate What You Need
              </h1>
              <p className="text-lg text-muted-foreground mb-6">
                Free online generators for passwords, QR codes, Lorem Ipsum
                text, hashes, and more. All tools run directly in your browser
                without sharing your data.
              </p>
              <div className="flex flex-wrap gap-4">
                <div className="flex items-center text-sm">
                  <Key className="h-4 w-4 mr-2 text-blue-500" />
                  <span>Password Generators</span>
                </div>
                <div className="flex items-center text-sm">
                  <QrCode className="h-4 w-4 mr-2 text-cyan-500" />
                  <span>Code Generators</span>
                </div>
                <div className="flex items-center text-sm">
                  <FileText className="h-4 w-4 mr-2 text-teal-500" />
                  <span>Text Generators</span>
                </div>
                <div className="flex items-center text-sm">
                  <Hash className="h-4 w-4 mr-2 text-indigo-500" />
                  <span>Hash Generators</span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <Link to="/generators/password-generator" className="block">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border border-blue-200 dark:border-blue-900 h-full">
                  <div className="bg-blue-100 dark:bg-blue-900/30 rounded-full p-2 mb-4">
                    <Lock className="h-8 w-8 text-blue-500" />
                  </div>
                  <div className="px-1 py-0.5 text-xs rounded-full bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100 mb-2">
                    POPULAR
                  </div>
                  <h3 className="font-medium mb-2">Password Generator</h3>
                  <p className="text-sm text-muted-foreground">
                    Create strong, secure passwords instantly
                  </p>
                </div>
              </Link>
              <Link to="/generators/qr-code-generator" className="block">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border h-full">
                  <QrCode className="h-12 w-12 text-cyan-500 mb-4" />
                  <h3 className="font-medium mb-2">QR Code Generator</h3>
                  <p className="text-sm text-muted-foreground">
                    Create QR codes for URLs, text, or contact info
                  </p>
                </div>
              </Link>
              <Link to="/generators/lorem-ipsum-generator" className="block">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border h-full">
                  <FileText className="h-12 w-12 text-teal-500 mb-4" />
                  <h3 className="font-medium mb-2">Lorem Ipsum</h3>
                  <p className="text-sm text-muted-foreground">
                    Generate placeholder text for design mockups
                  </p>
                </div>
              </Link>
              <Link to="/generators/hash-generator" className="block">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border h-full">
                  <Hash className="h-12 w-12 text-indigo-500 mb-4" />
                  <h3 className="font-medium mb-2">Hash Generator</h3>
                  <p className="text-sm text-muted-foreground">
                    Generate MD5, SHA-1, SHA-256, and other hashes
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Tool Section */}
      <section className="container">
        <div className="bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-blue-950/30 dark:to-indigo-950/30 rounded-xl overflow-hidden py-8 px-6">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <div className="flex items-center mb-2">
                <Lock className="h-5 w-5 mr-2 text-blue-500" />
                <span className="text-sm font-medium text-blue-500">
                  FEATURED GENERATOR
                </span>
              </div>
              <h2 className="text-2xl font-bold mb-4">Password Generator</h2>
              <p className="text-muted-foreground mb-6">
                Create secure, random passwords with our advanced password
                generator. Customize length, character types, and generate
                multiple passwords at once. All generation happens in your
                browser for maximum security.
              </p>
              <div className="space-y-3">
                <div className="flex items-start">
                  <Shuffle className="h-5 w-5 mr-2 text-blue-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">Customizable Complexity</h4>
                    <p className="text-sm text-muted-foreground">
                      Choose password length and which character types to
                      include
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <Lock className="h-5 w-5 mr-2 text-blue-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">High Entropy Generation</h4>
                    <p className="text-sm text-muted-foreground">
                      Uses cryptographically secure random number generation
                      algorithms
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <Wand2 className="h-5 w-5 mr-2 text-blue-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">Password Strength Meter</h4>
                    <p className="text-sm text-muted-foreground">
                      Instantly see how strong your generated password is
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <Link to="/generators/password-generator">
                  <Button>Try Password Generator</Button>
                </Link>
              </div>
            </div>
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden border">
              <div className="p-6">
                <div className="bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-blue-900/20 dark:to-indigo-900/20 rounded-lg p-6">
                  <div className="flex justify-between items-center mb-6">
                    <h3 className="font-medium">Password Generator</h3>
                    <div className="bg-white dark:bg-gray-800 px-3 py-1 rounded-full text-xs shadow-sm">
                      Security Level: High
                    </div>
                  </div>

                  <div className="space-y-4 mb-6">
                    <div className="bg-white dark:bg-gray-800 p-3 rounded-md border border-gray-200 dark:border-gray-600 flex items-center justify-between">
                      <code className="text-sm font-mono text-blue-600 dark:text-blue-400">
                        X8q#2pL$7!mZ5vR9
                      </code>
                      <Button variant="ghost" size="icon" className="h-8 w-8">
                        <Shuffle className="h-4 w-4" />
                      </Button>
                    </div>

                    <div className="space-y-1">
                      <label className="text-sm font-medium">
                        Password Length
                      </label>
                      <div className="flex items-center">
                        <div className="h-2 flex-1 bg-gradient-to-r from-red-400 via-yellow-400 to-green-400 rounded-full"></div>
                        <span className="ml-2 text-sm font-medium">16</span>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-2">
                      <div className="flex items-center space-x-2">
                        <div className="h-4 w-4 rounded border border-gray-300 dark:border-gray-600 flex items-center justify-center bg-blue-500">
                          <svg
                            className="h-3 w-3 text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <label className="text-sm">Uppercase</label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="h-4 w-4 rounded border border-gray-300 dark:border-gray-600 flex items-center justify-center bg-blue-500">
                          <svg
                            className="h-3 w-3 text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <label className="text-sm">Lowercase</label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="h-4 w-4 rounded border border-gray-300 dark:border-gray-600 flex items-center justify-center bg-blue-500">
                          <svg
                            className="h-3 w-3 text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <label className="text-sm">Numbers</label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="h-4 w-4 rounded border border-gray-300 dark:border-gray-600 flex items-center justify-center bg-blue-500">
                          <svg
                            className="h-3 w-3 text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <label className="text-sm">Symbols</label>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <div className="flex items-center justify-between">
                      <span className="text-sm">Password Strength</span>
                      <span className="text-sm font-medium text-green-500">
                        Very Strong
                      </span>
                    </div>
                    <div className="h-2 w-full bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-green-500 rounded-full"
                        style={{ width: "90%" }}
                      ></div>
                    </div>
                    <p className="text-xs text-muted-foreground mt-1">
                      Would take ~3 million years to crack with modern computing
                      power
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Tools Grid Section */}
      <section>
        <div className="container">
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Generator Tools</h2>
            <p className="text-muted-foreground">
              All generators run entirely in your browser. Your data never
              leaves your device, ensuring complete privacy.
            </p>
          </div>
          <ToolGrid />
        </div>
      </section>

      {/* Security Tips Section */}
      <section className="bg-gray-50 dark:bg-gray-900/50 py-8 rounded-lg">
        <div className="container px-4 md:px-6">
          <h2 className="text-2xl font-bold mb-6">Security Tips</h2>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <Lock className="h-5 w-5 mr-2 text-blue-500" />
                Password Best Practices
              </h3>
              <p className="text-sm text-muted-foreground">
                Use unique passwords for each account, at least 12 characters
                long, with a mix of uppercase, lowercase, numbers, and symbols.
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <Hash className="h-5 w-5 mr-2 text-indigo-500" />
                Hash Security
              </h3>
              <p className="text-sm text-muted-foreground">
                For security applications, use modern hash algorithms like
                SHA-256 or better. Never use MD5 or SHA-1 for securing sensitive
                data.
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <QrCode className="h-5 w-5 mr-2 text-cyan-500" />
                QR Code Safety
              </h3>
              <p className="text-sm text-muted-foreground">
                Before scanning a QR code, ensure it comes from a trusted
                source. Malicious QR codes can lead to phishing sites or
                download malware.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GeneratorsPage;
