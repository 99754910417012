import React, { useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, Text, Box, Edges } from "@react-three/drei";
import { useSpacing } from "./SpacingContext";

// Layer component that renders a box for each part of the box model
interface LayerProps {
  width: number;
  height: number;
  depth: number;
  color: string;
  wireframe?: boolean;
  label?: string;
  labelPosition?: [number, number, number];
  opacity?: number;
  position?: [number, number, number];
}

const Layer: React.FC<LayerProps> = ({
  width,
  height,
  depth,
  color,
  wireframe = false,
  label,
  labelPosition,
  opacity = 1,
  position = [0, 0, 0],
}) => {
  return (
    <group position={position}>
      <Box args={[width, height, depth]} position={[0, 0, 0]}>
        <meshStandardMaterial
          color={color}
          wireframe={wireframe}
          transparent={opacity < 1}
          opacity={opacity}
        />
        {!wireframe && <Edges color="#ffffff" threshold={15} />}
      </Box>
      {label && (
        <Text
          position={labelPosition || [0, 0, depth / 2 + 0.1]}
          fontSize={0.2}
          color="#000000"
          anchorX="center"
          anchorY="middle"
        >
          {label}
        </Text>
      )}
    </group>
  );
};

// Animated camera rig component
const CameraRig: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useFrame(({ camera, clock }) => {
    // Optional: Add subtle camera movement for a more dynamic view
    camera.position.y = Math.sin(clock.getElapsedTime() * 0.3) * 0.2 + 2;
  });

  return <>{children}</>;
};

// Main BoxModel3D component
const BoxModel3D: React.FC = () => {
  const spacing = useSpacing();
  const [autoRotate, setAutoRotate] = useState(true);

  // Scale down the values for better visualization
  const scale = 0.01;
  const scaleValue = (value: number) => Math.max(value * scale, 0.05);

  // Calculate dimensions
  const contentWidth = scaleValue(200);
  const contentHeight = scaleValue(100);
  const contentDepth = scaleValue(100);

  const paddingTop = scaleValue(spacing.paddingTop);
  const paddingRight = scaleValue(spacing.paddingRight);
  const paddingBottom = scaleValue(spacing.paddingBottom);
  const paddingLeft = scaleValue(spacing.paddingLeft);

  const borderWidth = scaleValue(spacing.borderWidth);

  const marginTop = scaleValue(spacing.marginTop);
  const marginRight = scaleValue(spacing.marginRight);
  const marginBottom = scaleValue(spacing.marginBottom);
  const marginLeft = scaleValue(spacing.marginLeft);

  // Calculate the width/height of each layer
  const paddingWidth = contentWidth + paddingLeft + paddingRight;
  const paddingHeight = contentHeight + paddingTop + paddingBottom;
  const paddingDepth = contentDepth;

  const borderWidth3D = paddingWidth + borderWidth * 2;
  const borderHeight = paddingHeight + borderWidth * 2;
  const borderDepth = paddingDepth + borderWidth * 2;

  const marginWidth = borderWidth3D + marginLeft + marginRight;
  const marginHeight = borderHeight + marginTop + marginBottom;
  const marginDepth = borderDepth;

  // Colors based on preview mode
  const themeMode = spacing.previewMode;
  const themeColors = {
    margin: themeMode === "dark" ? "#164e63" : "#e0f2fe",
    border: themeMode === "dark" ? "#083344" : "#f0f9ff",
    padding: themeMode === "dark" ? "#0e7490" : "#bae6fd",
    content: themeMode === "dark" ? "#0369a1" : "#0ea5e9",
  };

  // Toggle auto-rotation
  const toggleAutoRotate = () => setAutoRotate(!autoRotate);

  return (
    <div className="relative h-[400px] rounded-md overflow-hidden border border-slate-200 dark:border-slate-700">
      <button
        className="absolute top-2 right-2 z-10 bg-white dark:bg-slate-800 p-2 rounded-md shadow-sm text-xs"
        onClick={toggleAutoRotate}
      >
        {autoRotate ? "Stop Rotation" : "Auto Rotate"}
      </button>
      <Canvas camera={{ position: [0, 2, 5], fov: 45 }}>
        <CameraRig>
          <ambientLight intensity={0.5} />
          <spotLight
            position={[10, 10, 10]}
            angle={0.15}
            penumbra={1}
            intensity={1}
          />
          <pointLight position={[-10, -10, -10]} intensity={0.5} />

          {/* Margin Layer */}
          <Layer
            width={marginWidth}
            height={marginHeight}
            depth={marginDepth}
            color={themeColors.margin}
            opacity={0.4}
          />

          {/* Border Layer */}
          <Layer
            width={borderWidth3D}
            height={borderHeight}
            depth={borderDepth}
            color={themeColors.border}
            opacity={0.6}
          />

          {/* Padding Layer */}
          <Layer
            width={paddingWidth}
            height={paddingHeight}
            depth={paddingDepth}
            color={themeColors.padding}
            opacity={0.8}
          />

          {/* Content Layer */}
          <Layer
            width={contentWidth}
            height={contentHeight}
            depth={contentDepth}
            color={themeColors.content}
            label="Content"
          />

          <gridHelper
            args={[10, 20, "#909090", "#404040"]}
            position={[0, -marginHeight / 2 - 0.1, 0]}
          />
          <OrbitControls
            autoRotate={autoRotate}
            autoRotateSpeed={2}
            enablePan={true}
            enableZoom={true}
            enableRotate={true}
          />
        </CameraRig>
      </Canvas>
    </div>
  );
};

export default BoxModel3D;
