import { Button } from "@/components/ui/button";
import { useNavigate, useLocation } from "react-router-dom";
import { useTools } from "@/contexts/ToolsContext";
import { ChevronLeft } from "lucide-react";
import { useEffect, useState } from "react";

export const ToolView = () => {
  const { activeTool, setActiveTool, tools } = useTools();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initTool = async () => {
      setLoading(true);
      setError(null);

      try {
        // Get tool ID from the URL path
        const pathParts = location.pathname.split("/");
        console.log("Current path:", location.pathname);
        console.log("Path parts:", pathParts);
        console.log("Current active tool:", activeTool?.id);
        console.log(
          "All tools:",
          tools.map((t) => ({ id: t.id, url: t.url }))
        );

        if (pathParts.length > 2) {
          const toolPath = pathParts[pathParts.length - 1]; // Get the last segment of the path
          console.log("Tool path:", toolPath);

          // Try to find a tool with a matching URL path segment
          const matchingTool = tools.find((tool) => {
            const toolUrlParts = tool.url.split("/");
            const toolPathSegment = toolUrlParts[toolUrlParts.length - 1];
            return toolPathSegment === toolPath;
          });

          console.log("Matching tool found:", matchingTool?.id);

          if (matchingTool) {
            console.log("Setting active tool to:", matchingTool.id);
            setActiveTool(matchingTool.id);
          } else {
            setError(`Tool not found for path: ${toolPath}`);
          }
        }
      } catch (err) {
        console.error("Error initializing tool:", err);
        setError("Failed to initialize tool");
      } finally {
        setLoading(false);
      }
    };

    initTool();
  }, [location.pathname, setActiveTool, tools]);

  useEffect(() => {
    // Set document title if we have an active tool
    if (activeTool && activeTool.pageTitle) {
      document.title = activeTool.pageTitle;
    }
  }, [activeTool]);

  const handleBack = () => {
    // Determine where to navigate back to based on URL pattern
    const pathParts = location.pathname.split("/");
    if (pathParts.length > 2 && pathParts[1]) {
      // If we're in a path like /converters/unit-converter,
      // go back to the category page (/converters)
      navigate(`/${pathParts[1]}`);
    } else {
      // Default to homepage
      navigate("/");
    }
  };

  if (loading) {
    return <div className="text-center p-8">Loading tool...</div>;
  }

  if (error) {
    return (
      <div className="text-center p-8">
        <p className="text-red-500 mb-4">{error}</p>
        <Button variant="outline" onClick={handleBack}>
          <ChevronLeft className="mr-2 h-4 w-4" />
          Go Back
        </Button>
      </div>
    );
  }

  if (!activeTool) {
    return (
      <div className="text-center p-8">
        <p className="mb-4">Tool not found or not activated</p>
        <Button variant="outline" onClick={handleBack}>
          <ChevronLeft className="mr-2 h-4 w-4" />
          Go Back
        </Button>
      </div>
    );
  }

  const ToolComponent = activeTool.component;

  return (
    <div className="space-y-6">
      <div className="flex items-center space-x-4">
        <Button
          variant="outline"
          size="sm"
          onClick={handleBack}
          className="flex items-center space-x-2"
        >
          <ChevronLeft className="h-4 w-4" />
          <span>Back</span>
        </Button>
      </div>

      <div className="pb-12">
        <ToolComponent />
      </div>
    </div>
  );
};

export default ToolView;
