// Tailwind CSS utility helpers

// Color options
export const colorOptions = {
  backgrounds: [
    { value: 'bg-transparent', label: 'Transparent' },
    { value: 'bg-white', label: 'White' },
    { value: 'bg-black', label: 'Black' },
    { value: 'bg-gray-50', label: 'Gray 50' },
    { value: 'bg-gray-100', label: 'Gray 100' },
    { value: 'bg-gray-200', label: 'Gray 200' },
    { value: 'bg-gray-300', label: 'Gray 300' },
    { value: 'bg-gray-400', label: 'Gray 400' },
    { value: 'bg-gray-500', label: 'Gray 500' },
    { value: 'bg-gray-600', label: 'Gray 600' },
    { value: 'bg-gray-700', label: 'Gray 700' },
    { value: 'bg-gray-800', label: 'Gray 800' },
    { value: 'bg-gray-900', label: 'Gray 900' },
    { value: 'bg-red-100', label: 'Red 100' },
    { value: 'bg-red-500', label: 'Red 500' },
    { value: 'bg-red-700', label: 'Red 700' },
    { value: 'bg-yellow-100', label: 'Yellow 100' },
    { value: 'bg-yellow-500', label: 'Yellow 500' },
    { value: 'bg-yellow-700', label: 'Yellow 700' },
    { value: 'bg-green-100', label: 'Green 100' },
    { value: 'bg-green-500', label: 'Green 500' },
    { value: 'bg-green-700', label: 'Green 700' },
    { value: 'bg-blue-100', label: 'Blue 100' },
    { value: 'bg-blue-500', label: 'Blue 500' },
    { value: 'bg-blue-700', label: 'Blue 700' },
    { value: 'bg-indigo-100', label: 'Indigo 100' },
    { value: 'bg-indigo-500', label: 'Indigo 500' },
    { value: 'bg-indigo-700', label: 'Indigo 700' },
    { value: 'bg-purple-100', label: 'Purple 100' },
    { value: 'bg-purple-500', label: 'Purple 500' },
    { value: 'bg-purple-700', label: 'Purple 700' },
    { value: 'bg-pink-100', label: 'Pink 100' },
    { value: 'bg-pink-500', label: 'Pink 500' },
    { value: 'bg-pink-700', label: 'Pink 700' },
  ],
  text: [
    { value: 'text-white', label: 'White' },
    { value: 'text-black', label: 'Black' },
    { value: 'text-gray-50', label: 'Gray 50' },
    { value: 'text-gray-100', label: 'Gray 100' },
    { value: 'text-gray-200', label: 'Gray 200' },
    { value: 'text-gray-300', label: 'Gray 300' },
    { value: 'text-gray-400', label: 'Gray 400' },
    { value: 'text-gray-500', label: 'Gray 500' },
    { value: 'text-gray-600', label: 'Gray 600' },
    { value: 'text-gray-700', label: 'Gray 700' },
    { value: 'text-gray-800', label: 'Gray 800' },
    { value: 'text-gray-900', label: 'Gray 900' },
    { value: 'text-red-500', label: 'Red 500' },
    { value: 'text-red-700', label: 'Red 700' },
    { value: 'text-yellow-500', label: 'Yellow 500' },
    { value: 'text-yellow-700', label: 'Yellow 700' },
    { value: 'text-green-500', label: 'Green 500' },
    { value: 'text-green-700', label: 'Green 700' },
    { value: 'text-blue-500', label: 'Blue 500' },
    { value: 'text-blue-700', label: 'Blue 700' },
    { value: 'text-indigo-500', label: 'Indigo 500' },
    { value: 'text-indigo-700', label: 'Indigo 700' },
    { value: 'text-purple-500', label: 'Purple 500' },
    { value: 'text-purple-700', label: 'Purple 700' },
    { value: 'text-pink-500', label: 'Pink 500' },
    { value: 'text-pink-700', label: 'Pink 700' },
  ],
  borders: [
    { value: 'border-transparent', label: 'Transparent' },
    { value: 'border-white', label: 'White' },
    { value: 'border-black', label: 'Black' },
    { value: 'border-gray-100', label: 'Gray 100' },
    { value: 'border-gray-200', label: 'Gray 200' },
    { value: 'border-gray-300', label: 'Gray 300' },
    { value: 'border-gray-400', label: 'Gray 400' },
    { value: 'border-gray-500', label: 'Gray 500' },
    { value: 'border-gray-600', label: 'Gray 600' },
    { value: 'border-gray-700', label: 'Gray 700' },
    { value: 'border-gray-800', label: 'Gray 800' },
    { value: 'border-gray-900', label: 'Gray 900' },
    { value: 'border-red-500', label: 'Red 500' },
    { value: 'border-yellow-500', label: 'Yellow 500' },
    { value: 'border-green-500', label: 'Green 500' },
    { value: 'border-blue-500', label: 'Blue 500' },
    { value: 'border-indigo-500', label: 'Indigo 500' },
    { value: 'border-purple-500', label: 'Purple 500' },
    { value: 'border-pink-500', label: 'Pink 500' },
  ],
};

// Typography options
export const typographyOptions = {
  sizes: [
    { value: 'text-xs', label: 'Extra Small' },
    { value: 'text-sm', label: 'Small' },
    { value: 'text-base', label: 'Base' },
    { value: 'text-lg', label: 'Large' },
    { value: 'text-xl', label: 'Extra Large' },
    { value: 'text-2xl', label: '2XL' },
    { value: 'text-3xl', label: '3XL' },
    { value: 'text-4xl', label: '4XL' },
    { value: 'text-5xl', label: '5XL' },
    { value: 'text-6xl', label: '6XL' },
  ],
  weights: [
    { value: 'font-thin', label: 'Thin (100)' },
    { value: 'font-extralight', label: 'Extra Light (200)' },
    { value: 'font-light', label: 'Light (300)' },
    { value: 'font-normal', label: 'Normal (400)' },
    { value: 'font-medium', label: 'Medium (500)' },
    { value: 'font-semibold', label: 'Semi Bold (600)' },
    { value: 'font-bold', label: 'Bold (700)' },
    { value: 'font-extrabold', label: 'Extra Bold (800)' },
    { value: 'font-black', label: 'Black (900)' },
  ],
  families: [
    { value: 'font-sans', label: 'Sans Serif' },
    { value: 'font-serif', label: 'Serif' },
    { value: 'font-mono', label: 'Monospace' },
  ],
  alignments: [
    { value: 'text-left', label: 'Left' },
    { value: 'text-center', label: 'Center' },
    { value: 'text-right', label: 'Right' },
    { value: 'text-justify', label: 'Justify' },
  ],
};

// Spacing options
export const spacingOptions = {
  padding: [
    { value: 'p-0', label: '0' },
    { value: 'p-1', label: '1 (0.25rem)' },
    { value: 'p-2', label: '2 (0.5rem)' },
    { value: 'p-3', label: '3 (0.75rem)' },
    { value: 'p-4', label: '4 (1rem)' },
    { value: 'p-5', label: '5 (1.25rem)' },
    { value: 'p-6', label: '6 (1.5rem)' },
    { value: 'p-8', label: '8 (2rem)' },
    { value: 'p-10', label: '10 (2.5rem)' },
    { value: 'p-12', label: '12 (3rem)' },
    { value: 'p-16', label: '16 (4rem)' },
  ],
  paddingX: [
    { value: 'px-0', label: '0' },
    { value: 'px-1', label: '1 (0.25rem)' },
    { value: 'px-2', label: '2 (0.5rem)' },
    { value: 'px-3', label: '3 (0.75rem)' },
    { value: 'px-4', label: '4 (1rem)' },
    { value: 'px-5', label: '5 (1.25rem)' },
    { value: 'px-6', label: '6 (1.5rem)' },
    { value: 'px-8', label: '8 (2rem)' },
    { value: 'px-10', label: '10 (2.5rem)' },
    { value: 'px-12', label: '12 (3rem)' },
    { value: 'px-16', label: '16 (4rem)' },
  ],
  paddingY: [
    { value: 'py-0', label: '0' },
    { value: 'py-1', label: '1 (0.25rem)' },
    { value: 'py-2', label: '2 (0.5rem)' },
    { value: 'py-3', label: '3 (0.75rem)' },
    { value: 'py-4', label: '4 (1rem)' },
    { value: 'py-5', label: '5 (1.25rem)' },
    { value: 'py-6', label: '6 (1.5rem)' },
    { value: 'py-8', label: '8 (2rem)' },
    { value: 'py-10', label: '10 (2.5rem)' },
    { value: 'py-12', label: '12 (3rem)' },
    { value: 'py-16', label: '16 (4rem)' },
  ],
  margin: [
    { value: 'm-0', label: '0' },
    { value: 'm-1', label: '1 (0.25rem)' },
    { value: 'm-2', label: '2 (0.5rem)' },
    { value: 'm-3', label: '3 (0.75rem)' },
    { value: 'm-4', label: '4 (1rem)' },
    { value: 'm-5', label: '5 (1.25rem)' },
    { value: 'm-6', label: '6 (1.5rem)' },
    { value: 'm-8', label: '8 (2rem)' },
    { value: 'm-10', label: '10 (2.5rem)' },
    { value: 'm-12', label: '12 (3rem)' },
    { value: 'm-16', label: '16 (4rem)' },
    { value: 'm-auto', label: 'Auto' },
  ],
  marginX: [
    { value: 'mx-0', label: '0' },
    { value: 'mx-1', label: '1 (0.25rem)' },
    { value: 'mx-2', label: '2 (0.5rem)' },
    { value: 'mx-3', label: '3 (0.75rem)' },
    { value: 'mx-4', label: '4 (1rem)' },
    { value: 'mx-5', label: '5 (1.25rem)' },
    { value: 'mx-6', label: '6 (1.5rem)' },
    { value: 'mx-8', label: '8 (2rem)' },
    { value: 'mx-10', label: '10 (2.5rem)' },
    { value: 'mx-12', label: '12 (3rem)' },
    { value: 'mx-16', label: '16 (4rem)' },
    { value: 'mx-auto', label: 'Auto' },
  ],
  marginY: [
    { value: 'my-0', label: '0' },
    { value: 'my-1', label: '1 (0.25rem)' },
    { value: 'my-2', label: '2 (0.5rem)' },
    { value: 'my-3', label: '3 (0.75rem)' },
    { value: 'my-4', label: '4 (1rem)' },
    { value: 'my-5', label: '5 (1.25rem)' },
    { value: 'my-6', label: '6 (1.5rem)' },
    { value: 'my-8', label: '8 (2rem)' },
    { value: 'my-10', label: '10 (2.5rem)' },
    { value: 'my-12', label: '12 (3rem)' },
    { value: 'my-16', label: '16 (4rem)' },
    { value: 'my-auto', label: 'Auto' },
  ],
};

// Border options
export const borderOptions = {
  widths: [
    { value: 'border-0', label: 'None' },
    { value: 'border', label: 'Default (1px)' },
    { value: 'border-2', label: '2px' },
    { value: 'border-4', label: '4px' },
    { value: 'border-8', label: '8px' },
  ],
  radii: [
    { value: 'rounded-none', label: 'None' },
    { value: 'rounded-sm', label: 'Small' },
    { value: 'rounded', label: 'Default' },
    { value: 'rounded-md', label: 'Medium' },
    { value: 'rounded-lg', label: 'Large' },
    { value: 'rounded-xl', label: 'Extra Large' },
    { value: 'rounded-2xl', label: '2XL' },
    { value: 'rounded-3xl', label: '3XL' },
    { value: 'rounded-full', label: 'Full' },
  ],
  styles: [
    { value: 'border-solid', label: 'Solid' },
    { value: 'border-dashed', label: 'Dashed' },
    { value: 'border-dotted', label: 'Dotted' },
    { value: 'border-double', label: 'Double' },
    { value: 'border-none', label: 'None' },
  ],
};

// Shadow options
export const shadowOptions = {
  types: [
    { value: 'shadow-none', label: 'None' },
    { value: 'shadow', label: 'Default' },
    { value: 'shadow-sm', label: 'Small' },
    { value: 'shadow-md', label: 'Medium' },
    { value: 'shadow-lg', label: 'Large' },
    { value: 'shadow-xl', label: 'Extra Large' },
    { value: 'shadow-2xl', label: '2XL' },
    { value: 'shadow-inner', label: 'Inner' },
  ],
};

// Functions for generating class lists
export const extractTailwindClasses = (html: string): string[] => {
  const classMatches = html.match(/class="([^"]+)"/g);
  if (!classMatches) return [];
  
  const classes: string[] = [];
  
  classMatches.forEach(match => {
    const extractedClasses = match
      .replace('class="', '')
      .replace('"', '')
      .split(' ')
      .filter(c => c.trim().length > 0);
    
    classes.push(...extractedClasses);
  });
  
  return [...new Set(classes)]; // Remove duplicates
};

// Function to check if a string is a valid Tailwind class
export const isTailwindClass = (className: string): boolean => {
  // This is a simple validation that checks for common Tailwind patterns
  // A more comprehensive validation would need to use Tailwind's actual class list
  const patterns = [
    /^bg-/,
    /^text-/,
    /^font-/,
    /^m[tblrxy]?-/,
    /^p[tblrxy]?-/,
    /^w-/,
    /^h-/,
    /^border/,
    /^rounded/,
    /^shadow/,
    /^flex/,
    /^grid/,
    /^gap-/,
    /^space-/,
    /^justify-/,
    /^items-/,
    /^overflow-/,
    /^transition/,
    /^transform/,
    /^translate-/,
    /^rotate-/,
    /^scale-/,
    /^hover:/,
    /^focus:/,
    /^active:/,
    /^disabled:/,
  ];
  
  return patterns.some(pattern => pattern.test(className));
}; 