import React, { forwardRef } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card } from "@/components/ui/card";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import json from "react-syntax-highlighter/dist/esm/languages/hljs/json";
import xml from "react-syntax-highlighter/dist/esm/languages/hljs/xml";
import yaml from "react-syntax-highlighter/dist/esm/languages/hljs/yaml";
import csv from "react-syntax-highlighter/dist/esm/languages/hljs/plaintext";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";

// Register languages
SyntaxHighlighter.registerLanguage("json", json);
SyntaxHighlighter.registerLanguage("xml", xml);
SyntaxHighlighter.registerLanguage("yaml", yaml);
SyntaxHighlighter.registerLanguage("csv", csv);

interface FormatPreviewProps extends React.HTMLAttributes<HTMLDivElement> {
  content: string;
  format: string;
  title?: string;
  maxHeight?: string;
}

const FormatPreviewComponent = forwardRef<HTMLDivElement, FormatPreviewProps>(
  (
    { content, format, title = "Preview", maxHeight = "400px", ...props },
    ref
  ) => {
    // Map format to language for syntax highlighting
    const getLanguage = (format: string): string => {
      switch (format) {
        case "json":
          return "json";
        case "xml":
          return "xml";
        case "yaml":
          return "yaml";
        case "csv":
        case "tsv":
          return "csv";
        default:
          return "plaintext";
      }
    };

    // For CSV/TSV, create a table view
    const renderTableView = () => {
      if (!content) return null;

      // Determine delimiter
      let delimiter = ",";
      if (format === "tsv") delimiter = "\t";
      else if (content.includes(";") && !content.includes(",")) delimiter = ";";

      const lines = content.trim().split("\n");
      if (lines.length === 0) return null;

      const headers = lines[0].split(delimiter);
      const rows = lines.slice(1).map((line) => line.split(delimiter));

      return (
        <div className="overflow-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-muted">
                {headers.map((header, i) => (
                  <th key={i} className="border px-4 py-2 text-left">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, i) => (
                <tr
                  key={i}
                  className={i % 2 === 0 ? "bg-background" : "bg-muted/30"}
                >
                  {row.map((cell, j) => (
                    <td key={j} className="border px-4 py-2">
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    };

    // For JSON, create a tree view
    const renderJsonTree = () => {
      if (!content) return null;

      try {
        const jsonData = JSON.parse(content);

        // Simple recursive component to render JSON tree
        const JsonTree = ({ data }: { data: unknown }) => {
          if (data === null)
            return <span className="text-muted-foreground">null</span>;

          if (typeof data !== "object") {
            return (
              <span
                className={
                  typeof data === "string"
                    ? "text-green-500"
                    : typeof data === "number"
                    ? "text-blue-500"
                    : typeof data === "boolean"
                    ? "text-purple-500"
                    : "text-muted-foreground"
                }
              >
                {String(data)}
              </span>
            );
          }

          if (Array.isArray(data)) {
            return (
              <div className="pl-4 border-l border-muted">
                {data.map((item, index) => (
                  <div key={index} className="py-1">
                    <span className="text-muted-foreground">{index}: </span>
                    <JsonTree data={item} />
                  </div>
                ))}
              </div>
            );
          }

          return (
            <div className="pl-4 border-l border-muted">
              {Object.entries(data as Record<string, unknown>).map(
                ([key, value], index) => (
                  <div key={index} className="py-1">
                    <span className="text-yellow-500">{key}: </span>
                    <JsonTree data={value} />
                  </div>
                )
              )}
            </div>
          );
        };

        return (
          <div className="p-4 overflow-auto font-mono text-sm">
            <JsonTree data={jsonData} />
          </div>
        );
      } catch (e) {
        return (
          <div className="p-4 text-destructive">
            Invalid JSON: {e instanceof Error ? e.message : String(e)}
          </div>
        );
      }
    };

    return (
      <Card className="w-full" ref={ref} {...props}>
        <div className="p-4 border-b">
          <h3 className="text-lg font-medium">{title}</h3>
        </div>

        {["csv", "tsv"].includes(format) ? (
          <Tabs defaultValue="table">
            <div className="px-4 pt-2">
              <TabsList>
                <TabsTrigger value="table">Table View</TabsTrigger>
                <TabsTrigger value="code">Code View</TabsTrigger>
              </TabsList>
            </div>

            <TabsContent value="table" className="p-4">
              <div style={{ maxHeight }} className="overflow-auto">
                {renderTableView()}
              </div>
            </TabsContent>

            <TabsContent value="code" className="p-0">
              <div style={{ maxHeight }} className="overflow-auto">
                <SyntaxHighlighter
                  language={getLanguage(format)}
                  style={vs2015}
                  customStyle={{ margin: 0, borderRadius: "0 0 0.5rem 0.5rem" }}
                >
                  {content}
                </SyntaxHighlighter>
              </div>
            </TabsContent>
          </Tabs>
        ) : format === "json" ? (
          <Tabs defaultValue="code">
            <div className="px-4 pt-2">
              <TabsList>
                <TabsTrigger value="code">Code View</TabsTrigger>
                <TabsTrigger value="tree">Tree View</TabsTrigger>
              </TabsList>
            </div>

            <TabsContent value="code" className="p-0">
              <div style={{ maxHeight }} className="overflow-auto">
                <SyntaxHighlighter
                  language={getLanguage(format)}
                  style={vs2015}
                  customStyle={{ margin: 0, borderRadius: "0 0 0.5rem 0.5rem" }}
                >
                  {content}
                </SyntaxHighlighter>
              </div>
            </TabsContent>

            <TabsContent value="tree" className="p-0">
              <div style={{ maxHeight }} className="overflow-auto">
                {renderJsonTree()}
              </div>
            </TabsContent>
          </Tabs>
        ) : (
          <div style={{ maxHeight }} className="overflow-auto">
            <SyntaxHighlighter
              language={getLanguage(format)}
              style={vs2015}
              customStyle={{ margin: 0, borderRadius: "0 0 0.5rem 0.5rem" }}
            >
              {content}
            </SyntaxHighlighter>
          </div>
        )}
      </Card>
    );
  }
);

FormatPreviewComponent.displayName = "FormatPreview";

export const FormatPreview = FormatPreviewComponent;
