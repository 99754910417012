import { FC, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  vscDarkPlus,
  vs,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { Button } from "@/components/ui/button";
import { Check, Copy, Code, Sun, Moon } from "lucide-react";
import { toast } from "sonner";

interface ComponentCodeOutputProps {
  code: string;
  language: string;
  title?: string;
}

export const ComponentCodeOutput: FC<ComponentCodeOutputProps> = ({
  code,
  language,
  title,
}) => {
  const [copied, setCopied] = useState(false);
  const [theme, setTheme] = useState<"dark" | "light">("dark");

  // Handle copy code to clipboard
  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setCopied(true);
      toast.success("Code copied to clipboard");

      // Reset copied state after 2 seconds
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (error) {
      toast.error("Failed to copy code");
      console.error("Failed to copy code:", error);
    }
  };

  return (
    <div className="border rounded-md overflow-hidden">
      {/* Code header with title and actions */}
      <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 px-4 py-2 border-b">
        <div className="flex items-center gap-2">
          <Code size={16} />
          <span className="font-medium text-sm">
            {title || `${language.toUpperCase()} Code`}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
            className="h-8 w-8"
            title={`Switch to ${theme === "dark" ? "light" : "dark"} theme`}
          >
            {theme === "dark" ? <Sun size={16} /> : <Moon size={16} />}
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={handleCopyCode}
            className="h-8 w-8"
            disabled={copied}
            title="Copy code"
          >
            {copied ? (
              <Check size={16} className="text-green-500" />
            ) : (
              <Copy size={16} />
            )}
          </Button>
        </div>
      </div>

      {/* Code syntax highlighter */}
      <div className="max-h-[500px] overflow-auto">
        <SyntaxHighlighter
          language={language}
          style={theme === "dark" ? vscDarkPlus : vs}
          showLineNumbers
          customStyle={{
            margin: 0,
            borderRadius: 0,
            fontSize: "14px",
            padding: "1.5rem",
          }}
          lineNumberStyle={{
            color: theme === "dark" ? "#6e7681" : "#aaa",
            minWidth: "3em",
          }}
        >
          {code}
        </SyntaxHighlighter>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-2 border-t text-xs text-gray-500 dark:text-gray-400">
        <div className="flex items-center justify-between">
          <span>{code.split("\n").length} lines</span>
          <span>
            {language === "tsx"
              ? "TypeScript React"
              : language === "vue"
              ? "Vue.js"
              : "HTML"}
          </span>
        </div>
      </div>
    </div>
  );
};
