import React from "react";
import { useEffect, useState, useCallback, memo } from "react";
import { useTools } from "@/contexts/ToolsContext";
import { Button } from "@/components/ui/button";
import {
  Calculator,
  RotateCcw,
  Shapes,
  Settings,
  Search,
  Filter,
  ChevronRight,
  Dices,
  ArrowUp,
} from "lucide-react";
import { Link } from "react-router-dom";
import { Input } from "@/components/ui/input";
import { Tool } from "@/contexts/toolsData";
import { Separator } from "@/components/ui/separator";
import { motion, AnimatePresence } from "framer-motion";

// Memoized Tool Card component for better performance
const ToolCard = memo(({ tool, index }: { tool: Tool; index: number }) => (
  <motion.div
    key={tool.id}
    initial={{ y: 20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ delay: 0.2 + index * 0.15, duration: 0.5 }}
    whileHover={{
      scale: 1.03,
      boxShadow:
        "0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      transition: { duration: 0.2 },
    }}
  >
    <Link
      to={tool.url}
      className="bg-white dark:bg-slate-800 rounded-xl p-5 flex flex-col items-center justify-center text-center gap-2 h-full shadow-sm hover:shadow-md transition-all border border-slate-100 dark:border-slate-700"
      aria-label={`Open ${tool.title} tool`}
    >
      <div className="bg-gradient-to-br from-slate-100 to-slate-50 dark:from-slate-700 dark:to-slate-800 p-4 rounded-full">
        <tool.icon className="h-7 w-7 text-indigo-500" aria-hidden="true" />
      </div>
      <span className="font-medium mt-2 text-slate-900 dark:text-white">
        {tool.title}
      </span>
    </Link>
  </motion.div>
));

ToolCard.displayName = "ToolCard";

// Memoized Category Card component
const CategoryCard = memo(
  ({
    category,
    icon: Icon,
    color,
    description,
    delay,
  }: {
    category: string;
    icon: React.ElementType;
    color: string;
    description: string;
    delay: number;
  }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay }}
      whileHover={{ y: -5 }}
    >
      <Link to={`/${category.toLowerCase()}`} className="group block">
        <div
          className={`border border-${color}/10 rounded-lg p-6 h-full hover:shadow-lg transition-all duration-300 hover:border-${color} text-center group-hover:bg-gradient-to-br group-hover:from-${color}/5 group-hover:to-${color}/10`}
        >
          <div
            className={`bg-gradient-to-br from-${color}/20 to-${color}/5 p-3 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4 transform group-hover:scale-110 transition-transform duration-300`}
          >
            <Icon className={`h-8 w-8 text-${color}`} aria-hidden="true" />
          </div>
          <h3
            className={`font-medium mb-1 group-hover:text-${color} transition-colors`}
          >
            {category}
          </h3>
          <p className="text-sm text-muted-foreground">{description}</p>
        </div>
      </Link>
    </motion.div>
  )
);

CategoryCard.displayName = "CategoryCard";

// Memoized Search Result component
const SearchResult = memo(
  ({
    tool,
    index,
    onSelect,
  }: {
    tool: Tool;
    index: number;
    onSelect: () => void;
  }) => (
    <motion.div
      key={tool.id}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2, delay: index * 0.05 }}
    >
      <Link
        to={tool.url}
        className="block p-4 hover:bg-indigo-50/50 dark:hover:bg-indigo-900/20 flex items-center border-b border-indigo-100 dark:border-indigo-800/20 last:border-0 transition-colors"
        onClick={onSelect}
      >
        <div className="bg-gradient-to-br from-indigo-100 to-indigo-50 dark:from-indigo-900/30 dark:to-indigo-800/10 p-2 rounded-full mr-3">
          <tool.icon className="h-5 w-5 text-indigo-500" aria-hidden="true" />
        </div>
        <div className="flex-1 min-w-0">
          <div className="font-medium">{tool.title}</div>
          <div className="text-sm text-muted-foreground truncate">
            {tool.description}
          </div>
        </div>
      </Link>
    </motion.div>
  )
);

SearchResult.displayName = "SearchResult";

const HomePage = () => {
  const { setFilterCategory, tools } = useTools();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<Tool[]>([]);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [greeting, setGreeting] = useState("Welcome");

  // Get productivity tools for the hero section
  const productivityTools = tools
    .filter((tool) => tool.category === "productivity")
    .slice(0, 4);

  useEffect(() => {
    // Reset the filter category when the homepage loads
    setFilterCategory("all");

    // Set the document title directly
    document.title = "ToolNames - Free Browser-Based Tools and Utilities";

    // Set greeting based on time of day
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) {
      setGreeting("Good morning");
    } else if (hour >= 12 && hour < 18) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }

    // Add CSS for grid patterns
    const style = document.createElement("style");
    style.innerHTML = `
      .bg-grid-slate-200\\50 {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(226 232 240 / 0.5)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
      }
      .bg-grid-slate-700\\25 {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(51 65 85 / 0.25)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
      }
    `;
    document.head.appendChild(style);

    // Add scroll event listener for the back to top button
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      // Clean up the injected style element
      if (style && document.head.contains(style)) {
        document.head.removeChild(style);
      }
    };
  }, [setFilterCategory]);

  // Handle search functionality with debounce
  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchQuery.trim() === "") {
        setSearchResults([]);
        return;
      }

      const query = searchQuery.toLowerCase();
      const results = tools
        .filter(
          (tool) =>
            tool.title.toLowerCase().includes(query) ||
            tool.description.toLowerCase().includes(query) ||
            tool.category.toLowerCase().includes(query)
        )
        .slice(0, 5); // Limit to 5 results

      setSearchResults(results);
    }, 150); // Small debounce for better performance

    return () => clearTimeout(debounceTimer);
  }, [searchQuery, tools]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const clearSearch = useCallback(() => {
    setSearchQuery("");
  }, []);

  // Category configuration
  const categories = [
    {
      name: "Calculators",
      icon: Calculator,
      color: "indigo-500",
      description: "Financial, math, and utility calculators",
      delay: 0.1,
    },
    {
      name: "Converters",
      icon: RotateCcw,
      color: "violet-500",
      description: "Unit, color, and data format converters",
      delay: 0.2,
    },
    {
      name: "Generators",
      icon: Shapes,
      color: "blue-500",
      description: "Create passwords, QR codes, and more",
      delay: 0.3,
    },
    {
      name: "Utilities",
      icon: Settings,
      color: "emerald-500",
      description: "Text tools, encoders, and formatters",
      delay: 0.4,
    },
    {
      name: "Lottery",
      icon: Dices,
      color: "green-500",
      description: "Lottery tools, number generators, and analyzers",
      delay: 0.5,
    },
  ];

  // Get category colors for tool sections
  const categoryColors: Record<string, string> = {
    calculators: "indigo-500",
    converters: "violet-500",
    generators: "blue-500",
    utilities: "emerald-500",
    "file-tools": "teal-500",
    seo: "rose-500",
    productivity: "purple-500",
    design: "amber-500",
    lottery: "green-500",
  };

  return (
    <>
      <div className="space-y-12">
        {/* Hero Section */}
        <motion.section
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="bg-gradient-to-br from-slate-50 to-slate-200 dark:from-slate-900 dark:to-slate-800 rounded-xl p-10 shadow-xl relative overflow-hidden"
        >
          {/* Decorative elements with subtle animations */}
          <motion.div
            animate={{
              scale: [1, 1.02, 1],
              opacity: [0.5, 0.7, 0.5],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            className="absolute top-0 right-0 w-96 h-96 bg-indigo-500/10 rounded-full blur-3xl -translate-y-1/2 translate-x-1/4 mix-blend-multiply dark:mix-blend-soft-light"
            aria-hidden="true"
          />
          <motion.div
            animate={{
              scale: [1, 1.05, 1],
              opacity: [0.5, 0.7, 0.5],
            }}
            transition={{
              duration: 10,
              repeat: Infinity,
              repeatType: "reverse",
              delay: 1,
            }}
            className="absolute bottom-0 left-0 w-96 h-96 bg-violet-500/10 rounded-full blur-3xl translate-y-1/2 -translate-x-1/4 mix-blend-multiply dark:mix-blend-soft-light"
            aria-hidden="true"
          />
          <motion.div
            animate={{
              scale: [1, 1.03, 1],
              opacity: [0.3, 0.5, 0.3],
            }}
            transition={{
              duration: 12,
              repeat: Infinity,
              repeatType: "reverse",
              delay: 2,
            }}
            className="absolute top-1/2 left-1/4 w-64 h-64 bg-blue-500/5 rounded-full blur-3xl mix-blend-multiply dark:mix-blend-soft-light"
            aria-hidden="true"
          />

          {/* Grid pattern background */}
          <div
            className="absolute inset-0 bg-grid-slate-200\\50 dark:bg-grid-slate-700\\25 bg-[center_top_-1px] [mask-image:linear-gradient(to_bottom,white,transparent_60%)] dark:[mask-image:linear-gradient(to_bottom,white,transparent_75%)]"
            aria-hidden="true"
          ></div>

          <div className="grid lg:grid-cols-[1fr_auto] gap-10 relative z-10">
            <div className="space-y-6">
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="space-y-2"
              >
                <h1 className="text-5xl font-bold tracking-tight text-slate-900 dark:text-white">
                  <span className="inline-block bg-gradient-to-r from-indigo-500 to-violet-500 bg-clip-text text-transparent">
                    {greeting}
                  </span>
                </h1>
                <h2 className="text-4xl font-bold tracking-tight text-slate-900 dark:text-white">
                  Boost your productivity.
                </h2>
              </motion.div>

              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
                className="text-xl text-slate-600 dark:text-slate-300 max-w-lg"
              >
                Our browser-based productivity tools help you stay organized and
                efficient. No sign-ups, no data sharing – everything runs
                locally.
              </motion.p>

              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
                className="flex flex-wrap gap-4 pt-2"
              >
                <Button
                  asChild
                  size="lg"
                  className="bg-indigo-600 hover:bg-indigo-700 text-white dark:bg-indigo-500 dark:hover:bg-indigo-600 shadow-lg rounded-lg px-6 h-12 text-base focus:ring-2 focus:ring-indigo-500/50"
                >
                  <Link to="/productivity">
                    <Search className="mr-2 h-5 w-5" aria-hidden="true" />
                    Explore Productivity Tools
                  </Link>
                </Button>
                <Button
                  variant="outline"
                  size="lg"
                  asChild
                  className="border-slate-300 dark:border-slate-700 text-slate-900 dark:text-white hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg px-6 h-12 text-base focus:ring-2 focus:ring-indigo-500/50"
                >
                  <Link to="/sitemap">View All Tools</Link>
                </Button>
              </motion.div>
            </div>

            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className="grid sm:grid-cols-2 gap-4 min-w-[280px] md:min-w-[400px]"
            >
              {productivityTools.length > 0 ? (
                productivityTools.map((tool, index) => (
                  <ToolCard key={tool.id} tool={tool} index={index} />
                ))
              ) : (
                // Fallback in case there are no productivity tools
                <div className="col-span-2 flex items-center justify-center p-6 border border-dashed border-slate-300 dark:border-slate-700 rounded-xl">
                  <p className="text-center text-slate-500 dark:text-slate-400">
                    Productivity tools coming soon!
                  </p>
                </div>
              )}
            </motion.div>
          </div>

          {/* Scroll indicator */}
          <motion.div
            className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex flex-col items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.2, duration: 0.5 }}
            aria-hidden="true"
          >
            <motion.div
              animate={{ y: [0, 6, 0] }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: "loop",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="text-slate-400 dark:text-slate-500"
              >
                <path d="M12 5v14M5 12l7 7 7-7" />
              </svg>
            </motion.div>
            <span className="text-xs text-slate-400 dark:text-slate-500 mt-1">
              Scroll down
            </span>
          </motion.div>
        </motion.section>

        {/* Quick Search Section */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="bg-gradient-to-br from-indigo-50 via-violet-50 to-indigo-50 dark:from-indigo-900/20 dark:via-violet-900/10 dark:to-indigo-900/10 rounded-lg p-8 shadow-md relative overflow-hidden"
        >
          {/* Decorative elements */}
          <div
            className="absolute -top-16 -right-16 w-32 h-32 bg-indigo-500/10 rounded-full blur-2xl"
            aria-hidden="true"
          ></div>
          <div
            className="absolute -bottom-16 -left-16 w-32 h-32 bg-violet-500/10 rounded-full blur-2xl"
            aria-hidden="true"
          ></div>

          <div className="max-w-2xl mx-auto relative z-10">
            <motion.h2
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-2xl font-bold tracking-tight mb-6 text-center bg-gradient-to-r from-indigo-500 to-violet-500 bg-clip-text text-transparent"
            >
              Find The Tool You Need
            </motion.h2>
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="relative"
            >
              <div className="relative">
                <Input
                  type="text"
                  placeholder="Search for calculators, converters, generators..."
                  className="w-full pl-12 py-6 text-lg border-indigo-200 dark:border-indigo-800/50 focus-visible:ring-indigo-400 shadow-md hover:shadow-lg transition-shadow rounded-full"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  aria-label="Search tools"
                />
                <div className="absolute left-3 top-1/2 transform -translate-y-1/2 bg-indigo-100 dark:bg-indigo-900/30 p-2 rounded-full">
                  <Search
                    className="h-5 w-5 text-indigo-500"
                    aria-hidden="true"
                  />
                </div>
                {searchQuery && (
                  <button
                    onClick={clearSearch}
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-slate-400 hover:text-slate-600 dark:text-slate-500 dark:hover:text-slate-300 transition-colors"
                    aria-label="Clear search"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 5L5 15M5 5L15 15"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>

              <AnimatePresence>
                {searchResults.length > 0 && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ duration: 0.2 }}
                    className="absolute z-10 w-full mt-2 bg-background/90 backdrop-blur-sm rounded-lg shadow-lg border border-indigo-100 dark:border-indigo-800/20 overflow-hidden"
                    role="listbox"
                  >
                    {searchResults.map((tool, index) => (
                      <SearchResult
                        key={tool.id}
                        tool={tool}
                        index={index}
                        onSelect={clearSearch}
                      />
                    ))}

                    <div className="p-3 bg-muted/50 border-t border-indigo-100 dark:border-indigo-800/20 text-center text-sm">
                      <span className="text-muted-foreground">
                        Found {searchResults.length} results
                      </span>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>

              <div className="text-center mt-3 text-sm text-muted-foreground">
                <motion.p
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  Try searching for "calculator", "convert", "password", or
                  browse categories below
                </motion.p>
              </div>
            </motion.div>
          </div>
        </motion.section>

        {/* Category Navigation */}
        <section className="space-y-4">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="text-2xl font-bold tracking-tight bg-gradient-to-r from-indigo-500 to-violet-500 bg-clip-text text-transparent"
          >
            Categories
          </motion.h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {categories.map((category) => (
              <CategoryCard
                key={category.name}
                category={category.name}
                icon={category.icon}
                color={category.color}
                description={category.description}
                delay={category.delay}
              />
            ))}
          </div>
        </section>

        {/* Statistics Section */}
        <motion.section
          className="bg-gradient-to-r from-indigo-50 via-violet-50 to-slate-100 dark:from-indigo-900/20 dark:via-violet-900/10 dark:to-slate-800/30 rounded-lg p-10 shadow-md relative overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          {/* Decorative elements */}
          <div
            className="absolute top-0 right-0 w-64 h-64 bg-violet-500/10 rounded-full blur-3xl -translate-y-1/2 translate-x-1/3"
            aria-hidden="true"
          ></div>
          <div
            className="absolute bottom-0 left-0 w-64 h-64 bg-indigo-500/10 rounded-full blur-3xl translate-y-1/3 -translate-x-1/3"
            aria-hidden="true"
          ></div>

          <div className="text-center mb-8 relative z-10">
            <motion.h2
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-2xl font-bold tracking-tight bg-gradient-to-r from-indigo-500 to-violet-500 bg-clip-text text-transparent"
            >
              Why Choose Our Tools?
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="text-muted-foreground mt-2"
            >
              Built with privacy and performance in mind
            </motion.p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="bg-background/40 backdrop-blur-sm p-6 rounded-lg border border-indigo-200/30 dark:border-indigo-700/20 shadow-sm hover:shadow-md transition-shadow text-center space-y-3 group hover:border-indigo-300/50 dark:hover:border-indigo-600/30"
            >
              <div className="text-indigo-500 text-5xl font-bold bg-gradient-to-br from-indigo-500 to-violet-500 bg-clip-text text-transparent group-hover:scale-110 transform transition-transform">
                {tools.length}+
              </div>
              <h3 className="font-medium text-lg">Free Tools</h3>
              <div className="w-16 h-1 bg-gradient-to-r from-indigo-500 to-violet-500 rounded-full mx-auto"></div>
              <p className="text-sm text-muted-foreground">
                All tools are completely free and open-source
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="bg-background/40 backdrop-blur-sm p-6 rounded-lg border border-violet-200/30 dark:border-violet-700/20 shadow-sm hover:shadow-md transition-shadow text-center space-y-3 group hover:border-violet-300/50 dark:hover:border-violet-600/30"
            >
              <div className="text-violet-500 text-5xl font-bold bg-gradient-to-br from-violet-500 to-purple-500 bg-clip-text text-transparent group-hover:scale-110 transform transition-transform">
                100%
              </div>
              <h3 className="font-medium text-lg">Privacy-Focused</h3>
              <div className="w-16 h-1 bg-gradient-to-r from-violet-500 to-purple-500 rounded-full mx-auto"></div>
              <p className="text-sm text-muted-foreground">
                Your data never leaves your device
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="bg-background/40 backdrop-blur-sm p-6 rounded-lg border border-blue-200/30 dark:border-blue-700/20 shadow-sm hover:shadow-md transition-shadow text-center space-y-3 group hover:border-blue-300/50 dark:hover:border-blue-600/30"
            >
              <div className="text-blue-500 text-5xl font-bold bg-gradient-to-br from-blue-500 to-indigo-500 bg-clip-text text-transparent group-hover:scale-110 transform transition-transform">
                24/7
              </div>
              <h3 className="font-medium text-lg">Always Available</h3>
              <div className="w-16 h-1 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-full mx-auto"></div>
              <p className="text-sm text-muted-foreground">
                Tools work offline once loaded
              </p>
            </motion.div>
          </div>
        </motion.section>

        {/* All Tools by Category */}
        <section className="space-y-8" id="all-tools">
          <motion.div
            className="flex items-center justify-between"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-2xl font-bold tracking-tight bg-gradient-to-r from-indigo-500 to-violet-500 bg-clip-text text-transparent">
              All Tools
            </h2>

            <a
              href="#top"
              className="text-sm text-indigo-500 hover:text-indigo-600 transition-colors flex items-center"
            >
              <Filter className="h-4 w-4 mr-1" aria-hidden="true" />
              Filter
            </a>
          </motion.div>

          {/* Show all tools organized by category */}
          <div className="space-y-12">
            {[
              "calculators",
              "converters",
              "generators",
              "utilities",
              "file-tools",
              "seo",
              "productivity",
              "design",
              "lottery",
            ].map((category, index) => {
              const categoryTools = tools.filter(
                (tool) => tool.category === category
              );

              const color = categoryColors[category] || "indigo-500";
              const colorClass = color;

              return categoryTools.length > 0 ? (
                <motion.div
                  key={category}
                  className="space-y-4"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.1 * index }}
                >
                  {index > 0 && <Separator className="my-8" />}
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <h3
                        className={`text-xl font-semibold capitalize text-${colorClass}`}
                      >
                        {category.replace("-", " ")}
                      </h3>
                      <div
                        className={`h-1 w-12 bg-${colorClass} ml-4 rounded-full`}
                      ></div>
                    </div>

                    <Link
                      to={`/${category}`}
                      className={`text-sm text-${colorClass} hover:underline flex items-center`}
                    >
                      View all
                      <ChevronRight
                        className="h-4 w-4 ml-1"
                        aria-hidden="true"
                      />
                    </Link>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                    {categoryTools.map((tool, toolIndex) => (
                      <motion.div
                        key={tool.id}
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{
                          duration: 0.3,
                          delay: 0.1 + toolIndex * 0.05,
                        }}
                        whileHover={{
                          y: -5,
                          boxShadow:
                            "0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
                          transition: { duration: 0.2 },
                        }}
                      >
                        <Link
                          to={tool.url}
                          className="no-underline text-foreground group block h-full"
                          aria-label={`Open ${tool.title} tool`}
                        >
                          <div
                            className={`border border-${colorClass}/10 hover:border-${colorClass}/50 rounded-lg p-4 hover:shadow-md transition-all h-full flex flex-col group-hover:bg-gradient-to-br group-hover:from-${colorClass}/5 group-hover:to-${colorClass}/10`}
                          >
                            <div className="flex items-start mb-2">
                              <div
                                className={`bg-${colorClass}/10 p-2 rounded-full mr-2 group-hover:scale-110 transition-transform duration-300`}
                              >
                                <tool.icon
                                  className={`h-5 w-5 text-${colorClass}`}
                                  aria-hidden="true"
                                />
                              </div>
                              <h4
                                className={`font-medium group-hover:text-${colorClass} transition-colors`}
                              >
                                {tool.title}
                              </h4>
                            </div>
                            <p className="text-sm text-muted-foreground flex-grow">
                              {tool.description}
                            </p>
                            <div
                              className={`mt-3 text-xs text-${colorClass} opacity-0 group-hover:opacity-100 transition-opacity flex items-center`}
                            >
                              Use tool{" "}
                              <ChevronRight
                                className="h-3 w-3 ml-1"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        </Link>
                      </motion.div>
                    ))}
                  </div>
                </motion.div>
              ) : null;
            })}
          </div>
        </section>
      </div>

      {/* Back to Top Button */}
      <AnimatePresence>
        {showBackToTop && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
          >
            <Button
              className="fixed bottom-6 right-6 rounded-full p-3 shadow-lg bg-gradient-to-tr from-indigo-500 to-violet-500 hover:shadow-md hover:shadow-indigo-500/20 transform hover:scale-110 transition-all duration-300 border-none"
              onClick={scrollToTop}
              aria-label="Back to top"
            >
              <ArrowUp className="h-5 w-5 text-white" aria-hidden="true" />
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default HomePage;
