import React, { useEffect } from "react";
import { useTools } from "@/contexts/ToolsContext";
import { getCategoryName } from "@/contexts/toolsData";
import { ToolGrid } from "@/components/ToolGrid";
import {
  Calculator,
  Clock,
  Filter,
  BookOpen,
  Wrench,
  Settings,
  ArrowUpDown,
  Sparkles,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const UtilitiesPage: React.FC = () => {
  const { setFilterCategory } = useTools();
  const category = "utilities";

  // Set the filter category when the component mounts
  useEffect(() => {
    setFilterCategory(category);

    // Set the document title
    document.title = getCategoryTitle();
  }, [setFilterCategory]);

  // Generate SEO title based on category
  const getCategoryTitle = () => {
    const categoryName = getCategoryName(category);
    return `${categoryName} - Free Online ${categoryName} Tools | ToolNames`;
  };

  return (
    <div className="space-y-12 pb-8">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-emerald-50 to-green-50 dark:from-emerald-950/30 dark:to-green-950/30 rounded-xl overflow-hidden">
        <div className="container py-12 px-4 md:px-6">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h1 className="text-3xl md:text-4xl font-bold mb-4">
                Everyday Utilities, Simplified
              </h1>
              <p className="text-lg text-muted-foreground mb-6">
                Practical online tools to help with daily tasks, time
                management, calculations, and more. All utilities run directly
                in your browser with no data sharing.
              </p>
              <div className="flex flex-wrap gap-4">
                <div className="flex items-center text-sm">
                  <Clock className="h-4 w-4 mr-2 text-emerald-500" />
                  <span>Time Utilities</span>
                </div>
                <div className="flex items-center text-sm">
                  <Calculator className="h-4 w-4 mr-2 text-green-500" />
                  <span>Math Tools</span>
                </div>
                <div className="flex items-center text-sm">
                  <Filter className="h-4 w-4 mr-2 text-teal-500" />
                  <span>Sorting & Filtering</span>
                </div>
                <div className="flex items-center text-sm">
                  <BookOpen className="h-4 w-4 mr-2 text-cyan-500" />
                  <span>Text Utilities</span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <Link to="/utilities/countdown-timer" className="block">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border border-emerald-200 dark:border-emerald-900 h-full">
                  <div className="bg-emerald-100 dark:bg-emerald-900/30 rounded-full p-2 mb-4">
                    <Clock className="h-8 w-8 text-emerald-500" />
                  </div>
                  <div className="px-1 py-0.5 text-xs rounded-full bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100 mb-2">
                    POPULAR
                  </div>
                  <h3 className="font-medium mb-2">Countdown Timer</h3>
                  <p className="text-sm text-muted-foreground">
                    Create custom timers with alerts and notifications
                  </p>
                </div>
              </Link>
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border">
                <Calculator className="h-12 w-12 text-green-500 mb-4" />
                <h3 className="font-medium mb-2">Random Number Generator</h3>
                <p className="text-sm text-muted-foreground">
                  Generate random numbers with custom ranges
                </p>
              </div>
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border">
                <BookOpen className="h-12 w-12 text-teal-500 mb-4" />
                <h3 className="font-medium mb-2">Text Case Converter</h3>
                <p className="text-sm text-muted-foreground">
                  Convert text between different case formats
                </p>
              </div>
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border">
                <ArrowUpDown className="h-12 w-12 text-cyan-500 mb-4" />
                <h3 className="font-medium mb-2">List Sorter</h3>
                <p className="text-sm text-muted-foreground">
                  Sort and organize lists alphabetically or numerically
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Tool Section */}
      <section className="container">
        <div className="bg-gradient-to-r from-green-50 to-emerald-50 dark:from-green-950/30 dark:to-emerald-950/30 rounded-xl overflow-hidden py-8 px-6">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <div className="flex items-center mb-2">
                <Clock className="h-5 w-5 mr-2 text-emerald-500" />
                <span className="text-sm font-medium text-emerald-500">
                  FEATURED UTILITY
                </span>
              </div>
              <h2 className="text-2xl font-bold mb-4">Pomodoro Timer</h2>
              <p className="text-muted-foreground mb-6">
                Boost your productivity with our customizable Pomodoro Timer.
                The Pomodoro Technique is a time management method that uses
                alternating work and break periods to improve focus and
                productivity.
              </p>
              <div className="space-y-3">
                <div className="flex items-start">
                  <Settings className="h-5 w-5 mr-2 text-emerald-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">Customizable Intervals</h4>
                    <p className="text-sm text-muted-foreground">
                      Set your preferred work and break durations to match your
                      workflow
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <Sparkles className="h-5 w-5 mr-2 text-emerald-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">Audio Notifications</h4>
                    <p className="text-sm text-muted-foreground">
                      Get alerted when your work or break period ends with
                      customizable sound options
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <Wrench className="h-5 w-5 mr-2 text-emerald-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">Session Tracking</h4>
                    <p className="text-sm text-muted-foreground">
                      Keep track of completed pomodoro sessions with visual
                      progress indicators
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <Link to="/utilities/pomodoro-timer">
                  <Button>Try Pomodoro Timer</Button>
                </Link>
              </div>
            </div>
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden border">
              <div className="p-6">
                <div className="bg-gradient-to-br from-emerald-50 to-green-50 dark:from-emerald-900/20 dark:to-green-900/20 rounded-lg p-6">
                  <div className="flex justify-between items-center mb-6">
                    <h3 className="font-medium">Pomodoro Timer</h3>
                    <div className="bg-white dark:bg-gray-800 px-3 py-1 rounded-full text-xs shadow-sm">
                      Work Session
                    </div>
                  </div>

                  <div className="flex justify-center mb-6">
                    <div className="w-48 h-48 relative flex items-center justify-center rounded-full bg-white dark:bg-gray-800 shadow-md">
                      <div
                        className="absolute inset-0 rounded-full border-8 border-emerald-500"
                        style={{
                          clipPath: "polygon(0% 0%, 100% 0%, 100% 35%, 0% 35%)",
                        }}
                      ></div>
                      <div className="text-4xl font-bold text-gray-900 dark:text-white">
                        21:37
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center space-x-4 mb-6">
                    <button className="p-3 rounded-full bg-emerald-100 dark:bg-emerald-900/50 text-emerald-600 dark:text-emerald-400">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polygon points="5 3 19 12 5 21 5 3"></polygon>
                      </svg>
                    </button>
                    <button className="p-3 rounded-full bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-400">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <rect
                          x="4"
                          y="4"
                          width="16"
                          height="16"
                          rx="2"
                          ry="2"
                        ></rect>
                      </svg>
                    </button>
                    <button className="p-3 rounded-full bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-400">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="12"></line>
                        <line x1="12" y1="16" x2="12.01" y2="16"></line>
                      </svg>
                    </button>
                  </div>

                  <div className="space-y-3">
                    <div className="flex justify-between text-sm">
                      <span>Session</span>
                      <span className="font-medium">2/4</span>
                    </div>
                    <div className="h-2 w-full bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-emerald-500 rounded-full"
                        style={{ width: "50%" }}
                      ></div>
                    </div>
                    <div className="grid grid-cols-4 gap-2 mt-2">
                      <div className="h-2 bg-emerald-500 rounded-full"></div>
                      <div className="h-2 bg-emerald-500 rounded-full"></div>
                      <div className="h-2 bg-gray-200 dark:bg-gray-700 rounded-full"></div>
                      <div className="h-2 bg-gray-200 dark:bg-gray-700 rounded-full"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Tools Grid Section */}
      <section>
        <div className="container">
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">Utility Tools</h2>
            <p className="text-muted-foreground">
              All utilities run entirely in your browser. Your data never leaves
              your device, ensuring complete privacy.
            </p>
          </div>
          <ToolGrid />
        </div>
      </section>

      {/* Productivity Tips Section */}
      <section className="bg-gray-50 dark:bg-gray-900/50 py-8 rounded-lg">
        <div className="container px-4 md:px-6">
          <h2 className="text-2xl font-bold mb-6">Productivity Tips</h2>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <Clock className="h-5 w-5 mr-2 text-emerald-500" />
                Pomodoro Technique
              </h3>
              <p className="text-sm text-muted-foreground">
                Work in focused 25-minute intervals with short breaks in
                between. Complete four sessions, then take a longer break to
                maintain productivity.
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <BookOpen className="h-5 w-5 mr-2 text-teal-500" />
                Eat That Frog
              </h3>
              <p className="text-sm text-muted-foreground">
                Start your day by tackling the most challenging or important
                task first. This builds momentum and makes the rest of your
                tasks seem easier.
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <Wrench className="h-5 w-5 mr-2 text-green-500" />
                2-Minute Rule
              </h3>
              <p className="text-sm text-muted-foreground">
                If a task takes less than two minutes to complete, do it
                immediately rather than putting it off. This prevents small
                tasks from piling up.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UtilitiesPage;
