import React, { useEffect } from "react";
import { useTools } from "@/contexts/ToolsContext";
import { getCategoryName } from "@/contexts/toolsData";
import { ToolGrid } from "@/components/ToolGrid";
import {
  FileText,
  FileJson,
  FileSpreadsheet,
  FileImage,
  FilePlus,
  FileCode,
  FileMinus,
  FileArchive,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const FileToolsPage: React.FC = () => {
  const { setFilterCategory } = useTools();
  const category = "file-tools";

  // Set the filter category when the component mounts
  useEffect(() => {
    setFilterCategory(category);

    // Set the document title
    document.title = getCategoryTitle();
  }, [setFilterCategory]);

  // Generate SEO title based on category
  const getCategoryTitle = () => {
    const categoryName = getCategoryName(category);
    return `${categoryName} - Free Online ${categoryName} Tools | ToolNames`;
  };

  return (
    <div className="space-y-12 pb-8">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-teal-50 to-cyan-50 dark:from-teal-950/30 dark:to-cyan-950/30 rounded-xl overflow-hidden">
        <div className="container py-12 px-4 md:px-6">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h1 className="text-3xl md:text-4xl font-bold mb-4">
                Powerful File Tools in Your Browser
              </h1>
              <p className="text-lg text-muted-foreground mb-6">
                Free tools to help you convert, compress, edit, and analyze
                different file formats. All processing happens in your browser
                for complete privacy.
              </p>
              <div className="flex flex-wrap gap-4">
                <div className="flex items-center text-sm">
                  <FileJson className="h-4 w-4 mr-2 text-teal-500" />
                  <span>Data Files</span>
                </div>
                <div className="flex items-center text-sm">
                  <FileImage className="h-4 w-4 mr-2 text-cyan-500" />
                  <span>Image Files</span>
                </div>
                <div className="flex items-center text-sm">
                  <FileCode className="h-4 w-4 mr-2 text-blue-500" />
                  <span>Code Files</span>
                </div>
                <div className="flex items-center text-sm">
                  <FileText className="h-4 w-4 mr-2 text-indigo-500" />
                  <span>Text Files</span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <Link to="/file-tools/csv-explorer" className="block">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border border-teal-200 dark:border-teal-900 h-full">
                  <div className="bg-teal-100 dark:bg-teal-900/30 rounded-full p-2 mb-4">
                    <FileSpreadsheet className="h-8 w-8 text-teal-500" />
                  </div>
                  <div className="px-1 py-0.5 text-xs rounded-full bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100 mb-2">
                    POPULAR
                  </div>
                  <h3 className="font-medium mb-2">CSV Explorer</h3>
                  <p className="text-sm text-muted-foreground">
                    View, filter, and edit CSV files in an interactive table
                  </p>
                </div>
              </Link>
              <Link to="/file-tools/file-converter" className="block">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border border-teal-200 dark:border-teal-900 h-full">
                  <div className="bg-cyan-100 dark:bg-cyan-900/30 rounded-full p-2 mb-4">
                    <FileText className="h-8 w-8 text-cyan-500" />
                  </div>
                  <div className="px-1 py-0.5 text-xs rounded-full bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-100 mb-2">
                    NEW
                  </div>
                  <h3 className="font-medium mb-2">File Converter</h3>
                  <p className="text-sm text-muted-foreground">
                    Convert between CSV, JSON, XML, YAML, and Excel formats
                  </p>
                </div>
              </Link>
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border">
                <FileJson className="h-12 w-12 text-cyan-500 mb-4" />
                <h3 className="font-medium mb-2">JSON Formatter</h3>
                <p className="text-sm text-muted-foreground">
                  Format, validate, and convert JSON data
                </p>
              </div>
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border">
                <FileImage className="h-12 w-12 text-blue-500 mb-4" />
                <h3 className="font-medium mb-2">Image Resizer</h3>
                <p className="text-sm text-muted-foreground">
                  Resize and convert images between formats
                </p>
              </div>
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border">
                <FileArchive className="h-12 w-12 text-indigo-500 mb-4" />
                <h3 className="font-medium mb-2">File Compressor</h3>
                <p className="text-sm text-muted-foreground">
                  Compress files to reduce their size
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Tool Section */}
      <section className="container">
        <div className="bg-gradient-to-r from-cyan-50 to-teal-50 dark:from-cyan-950/30 dark:to-teal-950/30 rounded-xl overflow-hidden py-8 px-6">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <div className="flex items-center mb-2">
                <FileSpreadsheet className="h-5 w-5 mr-2 text-teal-500" />
                <span className="text-sm font-medium text-teal-500">
                  FEATURED TOOL
                </span>
              </div>
              <h2 className="text-2xl font-bold mb-4">CSV Explorer</h2>
              <p className="text-muted-foreground mb-6">
                Explore, analyze, and edit CSV data with our powerful CSV
                Explorer. Upload your CSV files and instantly view them in an
                interactive data table with searching, filtering, and editing
                capabilities.
              </p>
              <div className="space-y-3">
                <div className="flex items-start">
                  <FilePlus className="h-5 w-5 mr-2 text-teal-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">Interactive Data Table</h4>
                    <p className="text-sm text-muted-foreground">
                      View, sort, filter, and edit your data with an intuitive
                      interface
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <FileText className="h-5 w-5 mr-2 text-teal-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">Data Analysis</h4>
                    <p className="text-sm text-muted-foreground">
                      Get quick statistics and insights about your CSV data
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <FileMinus className="h-5 w-5 mr-2 text-teal-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">Export Options</h4>
                    <p className="text-sm text-muted-foreground">
                      Save your data in various formats including CSV, JSON, and
                      Excel
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <Link to="/file-tools/csv-explorer">
                  <Button>Try CSV Explorer</Button>
                </Link>
              </div>
            </div>
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden border">
              <div className="p-6">
                <div className="bg-gradient-to-br from-teal-50 to-cyan-50 dark:from-teal-900/20 dark:to-cyan-900/20 rounded-lg p-6">
                  <div className="flex justify-between items-center mb-6">
                    <h3 className="font-medium">CSV Explorer</h3>
                    <div className="bg-white dark:bg-gray-800 px-3 py-1 rounded-full text-xs shadow-sm">
                      538 rows × 12 columns
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden shadow-sm">
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                          <thead className="bg-gray-50 dark:bg-gray-800">
                            <tr>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                              >
                                ID
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                              >
                                Category
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                              >
                                Value
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700 text-xs">
                            <tr>
                              <td className="px-4 py-2 whitespace-nowrap">
                                1001
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                Product A
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                Electronics
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                $299.99
                              </td>
                            </tr>
                            <tr className="bg-gray-50 dark:bg-gray-700/20">
                              <td className="px-4 py-2 whitespace-nowrap">
                                1002
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                Product B
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                Home & Kitchen
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                $149.50
                              </td>
                            </tr>
                            <tr>
                              <td className="px-4 py-2 whitespace-nowrap">
                                1003
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                Product C
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                Electronics
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                $399.00
                              </td>
                            </tr>
                            <tr className="bg-gray-50 dark:bg-gray-700/20">
                              <td className="px-4 py-2 whitespace-nowrap">
                                1004
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                Product D
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                Sports
                              </td>
                              <td className="px-4 py-2 whitespace-nowrap">
                                $89.95
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="flex space-x-2">
                      <button className="px-2 py-1 bg-teal-100 dark:bg-teal-900/30 rounded text-xs text-teal-700 dark:text-teal-300">
                        Export
                      </button>
                      <button className="px-2 py-1 bg-gray-100 dark:bg-gray-700 rounded text-xs text-gray-700 dark:text-gray-300">
                        Filter
                      </button>
                    </div>
                    <div className="text-xs text-gray-500 dark:text-gray-400">
                      Showing 1-4 of 538 rows
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Tools Grid Section */}
      <section>
        <div className="container">
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">File Tools</h2>
            <p className="text-muted-foreground">
              All file processing happens in your browser. Your files never
              leave your device, ensuring complete privacy.
            </p>
          </div>
          <ToolGrid />
        </div>
      </section>

      {/* File Tips Section */}
      <section className="bg-gray-50 dark:bg-gray-900/50 py-8 rounded-lg">
        <div className="container px-4 md:px-6">
          <h2 className="text-2xl font-bold mb-6">File Handling Tips</h2>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <FileText className="h-5 w-5 mr-2 text-teal-500" />
                CSV Best Practices
              </h3>
              <p className="text-sm text-muted-foreground">
                Always include a header row in CSV files to identify columns,
                and use consistent formatting for dates and numbers to ensure
                proper analysis.
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <FileImage className="h-5 w-5 mr-2 text-cyan-500" />
                Image Optimization
              </h3>
              <p className="text-sm text-muted-foreground">
                Resize images to the dimensions they'll be displayed at and use
                appropriate formats: JPEG for photos, PNG for graphics with
                transparency, SVG for icons.
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <FileArchive className="h-5 w-5 mr-2 text-indigo-500" />
                Compression Guidelines
              </h3>
              <p className="text-sm text-muted-foreground">
                Use ZIP compression for multiple files, but be aware that
                certain formats like JPEG and MP3 are already compressed, so
                compressing them further yields minimal benefits.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FileToolsPage;
