import React, { useEffect } from "react";
import { useTools } from "@/contexts/ToolsContext";
import { getCategoryName } from "@/contexts/toolsData";
import { ToolGrid } from "@/components/ToolGrid";
import {
  Search,
  LineChart,
  AlignLeft,
  Activity,
  Tags,
  Link2,
  Globe,
  CheckCircle2,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const SEOPage: React.FC = () => {
  const { setFilterCategory } = useTools();
  const category = "seo";

  // Set the filter category when the component mounts
  useEffect(() => {
    setFilterCategory(category);

    // Set the document title
    document.title = getCategoryTitle();
  }, [setFilterCategory]);

  // Generate SEO title based on category
  const getCategoryTitle = () => {
    const categoryName = getCategoryName(category);
    return `${categoryName} - Free Online ${categoryName} Tools | ToolNames`;
  };

  return (
    <div className="space-y-12 pb-8">
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-rose-50 to-pink-50 dark:from-rose-950/30 dark:to-pink-950/30 rounded-xl overflow-hidden">
        <div className="container py-12 px-4 md:px-6">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <h1 className="text-3xl md:text-4xl font-bold mb-4">
                Boost Your Search Visibility
              </h1>
              <p className="text-lg text-muted-foreground mb-6">
                Free SEO tools to help you optimize your content, analyze
                keywords, and improve your search engine rankings. All tools run
                directly in your browser with no data sharing.
              </p>
              <div className="flex flex-wrap gap-4">
                <div className="flex items-center text-sm">
                  <AlignLeft className="h-4 w-4 mr-2 text-rose-500" />
                  <span>Content Tools</span>
                </div>
                <div className="flex items-center text-sm">
                  <Search className="h-4 w-4 mr-2 text-pink-500" />
                  <span>Keyword Research</span>
                </div>
                <div className="flex items-center text-sm">
                  <LineChart className="h-4 w-4 mr-2 text-red-500" />
                  <span>Analysis Tools</span>
                </div>
                <div className="flex items-center text-sm">
                  <Link2 className="h-4 w-4 mr-2 text-purple-500" />
                  <span>Link Tools</span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <Link to="/seo/meta-tag-generator" className="block">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border border-rose-200 dark:border-rose-900 h-full">
                  <div className="bg-rose-100 dark:bg-rose-900/30 rounded-full p-2 mb-4">
                    <Tags className="h-8 w-8 text-rose-500" />
                  </div>
                  <div className="px-1 py-0.5 text-xs rounded-full bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100 mb-2">
                    POPULAR
                  </div>
                  <h3 className="font-medium mb-2">Meta Tag Generator</h3>
                  <p className="text-sm text-muted-foreground">
                    Create optimized meta tags for better search visibility
                  </p>
                </div>
              </Link>
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border">
                <AlignLeft className="h-12 w-12 text-pink-500 mb-4" />
                <h3 className="font-medium mb-2">Content Analyzer</h3>
                <p className="text-sm text-muted-foreground">
                  Analyze content quality and readability scores
                </p>
              </div>
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border">
                <Search className="h-12 w-12 text-purple-500 mb-4" />
                <h3 className="font-medium mb-2">Keyword Density</h3>
                <p className="text-sm text-muted-foreground">
                  Check keyword frequency and density in your content
                </p>
              </div>
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm flex flex-col items-center text-center border">
                <Link2 className="h-12 w-12 text-red-500 mb-4" />
                <h3 className="font-medium mb-2">URL Parser</h3>
                <p className="text-sm text-muted-foreground">
                  Analyze and optimize URL structure for SEO
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Tool Section */}
      <section className="container">
        <div className="bg-gradient-to-r from-pink-50 to-rose-50 dark:from-pink-950/30 dark:to-rose-950/30 rounded-xl overflow-hidden py-8 px-6">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <div className="flex items-center mb-2">
                <Tags className="h-5 w-5 mr-2 text-rose-500" />
                <span className="text-sm font-medium text-rose-500">
                  FEATURED TOOL
                </span>
              </div>
              <h2 className="text-2xl font-bold mb-4">Meta Tag Generator</h2>
              <p className="text-muted-foreground mb-6">
                Create optimized meta tags that improve your search engine
                visibility and click-through rates. Our intelligent meta tag
                generator helps you craft titles and descriptions that follow
                SEO best practices.
              </p>
              <div className="space-y-3">
                <div className="flex items-start">
                  <Search className="h-5 w-5 mr-2 text-rose-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">SEO Best Practices</h4>
                    <p className="text-sm text-muted-foreground">
                      Get real-time feedback on title and description length and
                      keyword usage
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <Globe className="h-5 w-5 mr-2 text-rose-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">Social Media Preview</h4>
                    <p className="text-sm text-muted-foreground">
                      See how your page will appear on Google, Facebook,
                      Twitter, and more
                    </p>
                  </div>
                </div>
                <div className="flex items-start">
                  <CheckCircle2 className="h-5 w-5 mr-2 text-rose-500 mt-0.5" />
                  <div>
                    <h4 className="font-medium">One-Click HTML Generation</h4>
                    <p className="text-sm text-muted-foreground">
                      Generate HTML code for your meta tags with a single click
                      to copy and paste
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <Link to="/seo/meta-tag-generator">
                  <Button>Try Meta Tag Generator</Button>
                </Link>
              </div>
            </div>
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden border">
              <div className="p-6">
                <div className="bg-gradient-to-br from-rose-50 to-pink-50 dark:from-rose-900/20 dark:to-pink-900/20 rounded-lg p-6">
                  <div className="flex justify-between items-center mb-6">
                    <h3 className="font-medium">Meta Tag Generator</h3>
                    <div className="bg-white dark:bg-gray-800 px-3 py-1 rounded-full text-xs shadow-sm">
                      Preview Mode
                    </div>
                  </div>

                  <div className="space-y-4 mb-6">
                    <div>
                      <label className="text-sm font-medium mb-1 block">
                        Page Title
                      </label>
                      <div className="bg-white dark:bg-gray-700 rounded-md border border-gray-200 dark:border-gray-600 p-3 text-sm">
                        Best SEO Tools for 2023 | Free Online SEO Toolkit
                      </div>
                      <div className="flex items-center justify-between mt-1">
                        <span className="text-xs text-muted-foreground">
                          58 characters
                        </span>
                        <span className="text-xs text-green-500">
                          Optimal length
                        </span>
                      </div>
                    </div>

                    <div>
                      <label className="text-sm font-medium mb-1 block">
                        Meta Description
                      </label>
                      <div className="bg-white dark:bg-gray-700 rounded-md border border-gray-200 dark:border-gray-600 p-3 text-sm">
                        Improve your website's search visibility with our
                        collection of free SEO tools. Analyze content, generate
                        meta tags, and optimize your keywords.
                      </div>
                      <div className="flex items-center justify-between mt-1">
                        <span className="text-xs text-muted-foreground">
                          146 characters
                        </span>
                        <span className="text-xs text-green-500">
                          Optimal length
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="border border-gray-200 dark:border-gray-700 rounded-md overflow-hidden">
                    <div className="bg-gray-50 dark:bg-gray-800 p-2 border-b border-gray-200 dark:border-gray-700 text-xs font-medium">
                      Google Search Preview
                    </div>
                    <div className="p-3">
                      <div className="text-blue-600 dark:text-blue-400 text-base font-medium truncate">
                        Best SEO Tools for 2023 | Free Online SEO Toolkit
                      </div>
                      <div className="text-green-600 dark:text-green-400 text-xs truncate mt-1">
                        https://toolnames.com/seo-tools
                      </div>
                      <div className="text-sm mt-1 text-gray-600 dark:text-gray-300 line-clamp-2">
                        Improve your website's search visibility with our
                        collection of free SEO tools. Analyze content, generate
                        meta tags, and optimize your keywords.
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 flex justify-between">
                    <button className="text-xs px-3 py-1 rounded bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-300 border border-gray-200 dark:border-gray-600">
                      Toggle Preview
                    </button>
                    <button className="text-xs px-3 py-1 rounded bg-rose-100 dark:bg-rose-900/30 text-rose-700 dark:text-rose-300">
                      Copy HTML
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Tools Grid Section */}
      <section>
        <div className="container">
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">SEO Tools</h2>
            <p className="text-muted-foreground">
              All SEO tools run entirely in your browser. Your data never leaves
              your device, ensuring complete privacy.
            </p>
          </div>
          <ToolGrid />
        </div>
      </section>

      {/* SEO Tips Section */}
      <section className="bg-gray-50 dark:bg-gray-900/50 py-8 rounded-lg">
        <div className="container px-4 md:px-6">
          <h2 className="text-2xl font-bold mb-6">SEO Best Practices</h2>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <AlignLeft className="h-5 w-5 mr-2 text-rose-500" />
                Content Quality
              </h3>
              <p className="text-sm text-muted-foreground">
                Create comprehensive, unique content that provides value to
                users. Focus on addressing user intent and include relevant
                keywords naturally.
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <Tags className="h-5 w-5 mr-2 text-pink-500" />
                Meta Tag Optimization
              </h3>
              <p className="text-sm text-muted-foreground">
                Write compelling titles (50-60 characters) and meta descriptions
                (150-160 characters) that include target keywords and encourage
                click-throughs.
              </p>
            </div>
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm border">
              <h3 className="font-medium mb-3 flex items-center">
                <Activity className="h-5 w-5 mr-2 text-purple-500" />
                Page Speed
              </h3>
              <p className="text-sm text-muted-foreground">
                Optimize images, leverage browser caching, and minimize code to
                improve page load speed, as it's a significant ranking factor
                for both desktop and mobile searches.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SEOPage;
