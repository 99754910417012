import { FC } from "react";
import { ExportFormat } from "./types";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Copy, Download } from "lucide-react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { toast } from "sonner";

interface FlexboxCodeOutputProps {
  cssCode: string;
  scssCode: string;
  tailwindCode: string;
  format: ExportFormat;
  onFormatChange: (format: ExportFormat) => void;
}

export const FlexboxCodeOutput: FC<FlexboxCodeOutputProps> = ({
  cssCode,
  scssCode,
  tailwindCode,
  format,
  onFormatChange,
}) => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Code copied to clipboard!");
  };

  const downloadCode = (text: string, filename: string) => {
    const blob = new Blob([text], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    toast.success(`File downloaded: ${filename}`);
  };

  const getCurrentCode = (): string => {
    switch (format) {
      case "css":
        return cssCode;
      case "scss":
        return scssCode;
      case "tailwind":
        return tailwindCode;
      default:
        return cssCode;
    }
  };

  const getCurrentFilename = (): string => {
    switch (format) {
      case "css":
        return "flexbox-styles.css";
      case "scss":
        return "flexbox-styles.scss";
      case "tailwind":
        return "flexbox-tailwind.html";
      default:
        return "flexbox-styles.txt";
    }
  };

  return (
    <Card className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">Generated Code</h3>
        <div className="flex space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => copyToClipboard(getCurrentCode())}
          >
            <Copy className="mr-1 h-4 w-4" /> Copy
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => downloadCode(getCurrentCode(), getCurrentFilename())}
          >
            <Download className="mr-1 h-4 w-4" /> Download
          </Button>
        </div>
      </div>

      <Tabs
        value={format}
        onValueChange={(value) => onFormatChange(value as ExportFormat)}
      >
        <TabsList className="grid grid-cols-3 mb-4">
          <TabsTrigger value="css">CSS</TabsTrigger>
          <TabsTrigger value="scss">SCSS</TabsTrigger>
          <TabsTrigger value="tailwind">Tailwind</TabsTrigger>
        </TabsList>

        <TabsContent value="css" className="relative">
          <SyntaxHighlighter
            language="css"
            style={vscDarkPlus}
            customStyle={{
              borderRadius: "0.375rem",
              marginTop: 0,
              marginBottom: 0,
              fontSize: "0.875rem",
            }}
          >
            {cssCode}
          </SyntaxHighlighter>
        </TabsContent>

        <TabsContent value="scss" className="relative">
          <SyntaxHighlighter
            language="scss"
            style={vscDarkPlus}
            customStyle={{
              borderRadius: "0.375rem",
              marginTop: 0,
              marginBottom: 0,
              fontSize: "0.875rem",
            }}
          >
            {scssCode}
          </SyntaxHighlighter>
        </TabsContent>

        <TabsContent value="tailwind" className="relative">
          <SyntaxHighlighter
            language="html"
            style={vscDarkPlus}
            customStyle={{
              borderRadius: "0.375rem",
              marginTop: 0,
              marginBottom: 0,
              fontSize: "0.875rem",
            }}
          >
            {tailwindCode}
          </SyntaxHighlighter>
        </TabsContent>
      </Tabs>
    </Card>
  );
};
